import Vue from 'vue'
import Vuex from 'vuex'
import froalaconfig from './modules/froalaconfig'
import board from './modules/board'
import cockpit from './modules/cockpit'
import user from './modules/user'
import router from './modules/router';
import newsletter from './modules/newsletter';
import organizations from './modules/organizations'
import teams from './modules/teams'


Vue.use(Vuex)
Vue.config.devtools = true

const store = new Vuex.Store({
  state: {
    errpages: ['/400', '/401', '/402', '/403', '/404', '/405'],
    whiteListAuth: ['/apitoken','/','/set-password','ssosignin','/ssosignout','/sign-in-sso','/sign-out-sso','/sign-in-relay','/sign-in','/sign-up','/forgot-password','/forgot-password/','/password-reset','/password-reset/', '/email-change', '/email-change/','/tools-pages','/sign-out', '/registration/', '/registration', '/profiler','/avatarimages','/boards','/my/boards'], 
    whiteList: ['/apitoken','/','/set-password','ssosignin','/ssosignout','/sign-in-sso','/sign-out-sso','/sign-in-relay','/sign-in','/sign-up','/forgot-password','/forgot-password/','/password-reset','/password-reset/', '/email-change', '/email-change/', '/tools-pages','/sign-out', '/registration/', '/registration', '/profiler','/avatarimages','/boards','/my/boards'],
    screenType:'',
    screenSize:'',
    deviseType:navigator.userAgent,
    browsertype:navigator.appVersion,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false,
    hasTouch: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? false : true,
    showhints:false,
    forceRefresh:0,

  },
  mutations: {
    UPDATE_FORCEREFRESH(state){
      state.forceRefresh++
    },
    SET_SCREENTYPE(state,type){
      state.screenType = type
    },
    SET_DEVISETYPE(state, type){
      state.deviseType = type
    },
    SET_SCREENSIZE(state, size){
      state.screenSize = size
    },
     SET_SHOWHINTS(state,showhints){
      state.showhints = showhints

     }
  },
  actions: {
    SET_SCREENTYPE({commit},data){
      commit('SET_SCREENTYPE',data)
    },
     SET_DEVISETYPE({commit},data){
      commit('SET_DEVISETYPE',data)
    },
     SET_SCREENSIZE({commit},data){
      commit('SET_SCREENSIZE',data)
    }
  },
  getters:{
    get_routes: state => { 
      var rotuers = []
      rotuers.push.apply(rotuers, state.whiteListAuth)
      rotuers.push.apply(rotuers, state.errpages)
      return rotuers
    },
   
  },
  modules: {
    config: froalaconfig,
    board: board,
    cockpit: cockpit,
    user:user,
    router: router,
    newsletter:newsletter,
    organizations: organizations,
    teams: teams
  }
})

// window.addEventListener("resize", function(){
//     var h = window.$(froala['$box'])[0].clientWidth

//     if(h >= 600){
//       store.state.config.toolbarButtons = toolbarButtonsBig
//     } else{
//       console.log('froala height 600',  store.state.config.toolbarButtons)

//       store.state.config.toolbarButtons  = toolbarButtonsMini
//     }
//  });


export default store
