<template>
	
		<keep-alive>
			<router-view name ="mainview"></router-view>
		</keep-alive>
</template>

<script>
export default {
  name: 'AppMain'
}
</script>