import Newslettersapi from '@/services/api/newsletters'


const newsletter = {
    state:{
      activeNewsletter:{},
      typeofNewsletter:null,
      isactive: false,
      newsletter:[]
    },
    mutations:{
      SET_ACTIVENEWSLETTER(state,newsletter){
        state.activeNewsletter = newsletter
      },
      SET_TYPOEOFNEWSLETTER(state,type){
        state.typeofNewsletter = type
      },

      SET_ISACTIVE(state,prop){
        
        state.isactive = prop
      }
    },
    actions:{
      fecthNewsletterByid({commit},newsletterid){
        return new Promise((resolve, reject) =>{
           Newslettersapi.Getnewsletterbyid( newsletterid, localStorage.token).then(response =>{
            if(response.ok){
              commit('SET_ACTIVENEWSLETTER', response.data.newsletter);
              commit('SET_TYPOEOFNEWSLETTER',response.data.newsletter.ispublish)
              commit('SET_ISACTIVE',true)

            }
           }) .catch(error => {console.log(error); reject(error)})
          .finally( () => {

          })
        })
      },
    },
    getters:{
    },
    modules: {
    
  }
}


export default newsletter