<template>

	  <img v-if = "silent" src="@/static/silent.png"  :class="newclass" :id="newid" :width="width" :height = "height ">
      <img v-else-if = "noimage"  src="@/static/noimage.jpg"  :class="newclass" :id="newid" :width="width" :height = "height ">
      <img v-else-if = "src !='' "  :src ="src" :class="newclass" :id="newid" :width = "width" :height = "height " >
      <img v-else src="@/static/img/names/name_.png" :class="newclass" :id="newid" :height = "height ">


</template>

<!-- -->
<style lang ="scss">
	@import'@/scss/abstracts/variable.scss';
	.badge-secondary{
		background-color: #fff !important;
		color:$NO !important;


	}
</style>

<script type="text/javascript">
	
</script>

<script>
	export default{
		name:'avarar',
		props:{
			imgsrc:String,
			variant:String,
			name:String,
			silent:{
				type: Boolean,
				default: false
			},
			noimage:{
				type: Boolean,
				default: false
			},
			width:Number,
			height:Number,
			newclass:String,
			newid:String
		},
		computed:{
			src(){
				if(this.imgsrc !='' ){
					return this.imgsrc;
				} else if(this.name){
					return require('@/static/img/names/name_'+this.name.charAt(0).toLowerCase()+'.png');
				} else {
					return ''
				}

			}
		}
	}
	//  
</script>