<template>
	<div  :class = "contanerclassComp" >
		<label v-if ="label != ''">
			{{label}}
		</label>
		<input :value = "text" v-on ="listeners" v-bind="$attrs" class="form-control" @focus="addInputImg('clearstate', $event)" @change="addInputImg('', $event)">
		<slot name = "error">

			<span v-if= "showError" class = "text-danger">{{errortext}}</span>
		</slot>
	</div>
	
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name:'base-input',
		inheritAttrs: false,
		props:{
			label:{
				type: String,
				default:''
			},
			contanerclass:{
				type: String,
				default: ''
			},
			isrequired:{
				type: Boolean,
				default: false
			},
			isNotFrom:{
				type: Boolean,
				default: false
			},
			hasConfirmImg:{
				type: Boolean,
				default: false
			},
			hasErrorText:{
				type:String,
				default: ''
			},
			showError:{
				type:Boolean,
				default:true
			},
			inputName:{
				type: String,
				default: 'Name'
			},
			apiCall:{
				type: Function,
				default: function(callback){ if(callback){callback()}}
			},
			text:{
				type: String,
				default: ''
			},
			hasfocus:{
				type: Boolean,
				default: false
			},
			
		},
		 model: {
		    prop: 'text',
		    event: 'change'
		  },
		data(){
			return{

			}
		},
		mounted(){
			let self = this
			if (this.hasfocus){
				setTimeout(function (){
					$(`#${self.$attrs.id}`).focus()
				}, 100)
				
			}
		},
		computed:{
			errortext(){

				if(this.text == ''){
					return `${this.inputName} is required`
				}else {
					return this.hasErrorText
				}

			},

			contanerclassComp(){

				var str = this.contanerclass;
				if (!this.isNotFrom){
					str =+ ' form-group'
				}
				return str

			},

			listeners(){

				return{
					...this.$listeners,
					input: event =>
					this.$emit('input', event.target.value),
				}
			}
		},
		methods:{
			addInputImg(obj, ev){
				if(this.hasConfirmImg){
					if( obj == 'clearstate' ){

						$(ev.srcElement).parents('.form-group').removeClass("has-success")
						$(ev.srcElement).removeClass("form-control-success")

						$(ev.srcElement).parents('.form-group').removeClass("has-danger")
						$(ev.srcElement).removeClass("form-control-danger")

					} else if (this.text != ''){
						this.apiCall(function(){
							$(ev.srcElement).parents('.form-group').addClass("has-success")
							$(ev.srcElement).addClass("form-control-success")
							setTimeout(function(){
								$(ev.srcElement).parents('.form-group').removeClass("has-success")
								$(ev.srcElement).removeClass("form-control-success")
							},3000)
						})
					} else if(this.text == ''){
						$(ev.srcElement).parents('.form-group').addClass("has-danger")
						$(ev.srcElement).addClass("form-control-danger")
					}
				} 
				
			}

		}
		
	}
</script>