<script type="text/javascript"></script>
<script>
	import icon from "@/components/base/base-icon.vue"
	export default{
		name:"icon-with-tooltips",
		extends: icon,
		watch:{
			body: function(){
				
				var tooltip = $('[data-toggle="tooltip"]', this.body)

				if(this.$store.state.hasTouch && this.addTooltips){

            		tooltip.tooltip({delay: {show:500, hide:0}})

            		this.body.off('mouseenter').off('mouseleave')
            		this.body.on('mouseenter', function(e){
		              	tooltip.each(function(){
			                let self = this
			                setTimeout(function(){
			                  $(self).data('bs.tooltip').config.delay = {show:0, hide:0}
			                },500)                   
		            	})
		            }).on('mouseleave',function(e){
		              	tooltip.each(function(){
		                	let self = this
			                setTimeout(function(){
			                  $(self).data('bs.tooltip').config.delay = {show:500, hide:0}
			                })
		              	})
		             
            		})
          		}
			}
		},
		data(){
			return{

			}
		},

		props:{
			body:Object,
			index:Number,
			addTooltips:{
				type:Boolean,
				default:true,
			}
		},

		computed: {
			attrs(){

			}
		},

		methods:{

		}
	}
</script>