import router from '@/router'
import store from '@/store'

import cookie from 'vue-cookie'

//import authstore from '@/store/cognito-info-store';
//import auth from '@/cognito.js';

import Organizationsapi from '@/services/api/organizations'

var errpages = store.state.errpages
var whiteListAuth = store.state.whiteListAuth
var whiteList = store.state.whiteList

router.beforeEach((to, from, next) =>{


	//var listitems = store.state.organizations.listorganizations
	//|| listitems.length == 0
	//selectandreload
	let organizationkey = cookie.get('organizationkey')
	//let setorg = false

	if(organizationkey != to.params.orgname ){
		Organizationsapi.Select( store.getters.token, to.params.orgname)
		//store.dispatch('selectOrganizations',{token: store.getters.token, key: to.params.orgname})
	}

	if( to.fullPath.indexOf("?select") > -1  ){	
		

		store.dispatch('selectOrganizations',{token: store.getters.token, key: to.params.orgname}).then(( response ) => {

			if( response.data.organization ){
				router.replace(to.path)
				store.dispatch('fetchOrglist', {token: store.getters.token}).then(() => {})
			}else{
				alert("Page has been updated, will reload")
				location.reload();
			}
		})
	}

	if( to.fullPath.indexOf("?reloadnoaccess") > -1   ){
		

		store.dispatch('selectOrganizations',{token: store.getters.token, key: to.params.orgname}).then(( response ) => {

			if( response.data.organization ){

				router.replace(to.path)
				store.dispatch('fetchOrglist', {token: store.getters.token}).then(() => {
					next()
				})

			}else{
				alert("Page has been updated, will reload")
				location.reload();
			}

		})
	}

	//console.log( to ,"zzzzy")

	/*if( to.params.orgname ){

		store.dispatch('selectOrganizations',{token: store.getters.token, key: to.params.orgname}).then(( response ) => {

			//alert(response.data.ok)
			//console.log(response)
			if( response.data.ok ){
				store.dispatch('fechtOrganizations', {token: store.getters.token, key: to.params.orgname}).then(() => {})
			}else{

				if( response.data.error== 'novalidorganization' ){
					next('/403')
				}
				
			}
			
		})
			
	}*/


     //}else{
     //	store.dispatch('fechtOrganizations', {token: store.getters.token, key: to.params.orgname}).then(() => {})
     //}


     	var levs = to.path.split('/')

     	


		if( errpages.indexOf(to.path) > -1 ) {

			//alert('a')

			next()
		}else{


			store.dispatch('record', to)
			if (typeof to.params.unique_name !== 'undefined') {
				to.meta.title = to.meta.title + ' ' + to.params.unique_name
			}
			
			let resolver = router.resolve(to.path).route.name

			var usertoken = cookie.get('usertoken'); 

			if( usertoken == '' ){
				usertoken = localStorage.token
			}


			//alert( auth.auth.isUserSignedIn() )

			//alert( localStorage.token + " usertoken" )

			if ( usertoken && usertoken != '' ) {

				//alert(usertoken)

				if (whiteListAuth.indexOf(to.path.toLowerCase()) !== -1  && resolver !== '404' ) {
			/// dont need to revalidate user, front side will trust the token and the server will do the revalidation
					//alert('xx')

					next()

				} else {

				store.dispatch('fecthUserDetails', usertoken).then( function(response){
				
					if( response.data.ok ){                    

						localStorage.token = usertoken
						//localStorage.userdetails = JSON.stringify( response.data.user )

						if( response.data.user.organization ){
							cookie.set('organizationkey', response.data.user.organization.key); 
						}
						
				        next()

					    }else{

					    	cookie.set('usertoken',''); 
					    	localStorage.token = ""
					    	next("/sign-out")

					    }

					})
					.catch(error => console.log(error))
					.finally( () => {

					})
				}

			}else{

				if( levs[1] == 'sign-in-sso' || levs[1] == 'ssosignin' ){

					next()

				}else{


					if(whiteList.indexOf(to.path.toLowerCase()) == -1) {
						if(resolver == '400' || resolver == '401' || resolver == '403' || resolver == '404'){
							console.log('resolver')
							next('/'+resolver)
						}else{
								next('/sign-in?redirect='+ encodeURIComponent(to.fullPath) )
							}
						} 
					else {
						next()
					}

				}



			}

		}
	})


// var supportbrowser = function(){
// 	var ua = window.navigator.userAgent;
//     var msie = ua.indexOf("MISE");


// 	return msie

// }


