<template>
	<div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
		<div class="container">
			<p class="logo"><a href="/"><img src="/static/img/flowe.png" class="imglogo"></a></p>
			<div>
				<div class="pagetitle">
					<h2 class="text-center">Oops! That's a 404.</h2>
					<p class="text-center">Either you're lost, or we broke something.</p>
				</div>
				<div class="form-group responsepanel text-center">
					<a href="/" class="btn bt-xs btn-primary">Take me home</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name:'404error',
		mounted() { 
			$('#preloader').addClass('hide')
		}
	}
</script>