import Vue from 'vue'


const func  = { 
	addQuery(query, $route, $router){
		let obj = Object.assign({}, $route.query);

		Object.keys(query).forEach(key => {
			let value = query[key];
			if(value){
				obj[key] = value
			} else {
				delete obj[key]
			}
		})
		$router.replace({
			...$router.currentRoute,
			query: obj
		}).catch(()=>{});

		//.catch(err =>{console.log(err)})



	},
	
	removeQuery (queryNameArray, $route, $router) {
		let obj = {}
		queryNameArray.forEach(key => {
			obj[key] = null
		})
		this.addQuery(obj, $route, $router)
	},

	setQuery(query, $route, $router){
		let obj = new Object;

		Object.keys(query).forEach(key => {
			let value = query[key];
			if(value){
				obj[key] = value
			} else {
				delete obj[key]
			}
		})
		$router.replace({
			...$router.currentRoute,
			query: obj
		}).catch(err =>{console.log(err)})
	},

	oIsEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	},
		

}

Vue.prototype.$func = func