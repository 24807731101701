<template>   
	<div id="app" style="width:100%;" :key ="forcerefresh" >
		<b-modal v-model = "openguide" size ="l-x" centered>
			<template v-slot:modal-header>
				<h3 class="modal-title">First time here?</h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="openguide = false" ></i>
				</a>
			</template>
			Would you like to read the 'Getting started with Flowe' guide? The guide will open in a new browser tab or window.
			

			<template v-slot:modal-footer="{ cancel}" >
				<b-button @click= " cancel " variant ="secondary"> Cancel </b-button>
				<a class = "btn btn-primary" @click= " cancel " target="_blank" href="https://goflowe.atlassian.net/servicedesk/customer/portal/1/article/725057549?src=-292336437"> Open guide </a>
			</template>
		</b-modal>


		<!--  @dismissed="dimissdbanner" dismissible -->
		<b-alert variant = "warning" id= "browser-banner" :show.sync="showbanner" class ="text-center">
			<template v-if ="$store.state.screenType != 'mobile'">You are accessing Flowe on an unsupported browser and may experience difficulties with our site. We recommend you switch to one of our supported browsers. <router-link :to=" '/' + orgkey + '/help?page=about'" class = "actionwarningdark">Learn more</router-link>
			</template> 
			<template v-else>
				Unsupported browser. You may experience difficulties with our site. <router-link :to=" '/' + orgkey + '/help?page=about'" class = "actionwarningdark">Learn more</router-link>
			</template>

			<template  v-slot:dismiss>
				<i class=" mr-2 fal fa-times fa-sm " ></i>
			</template>
		</b-alert>

	 			<div style="position: fixed; padding: 5px; width: 200px; left: 50%; bottom: 40px; transform: translate(-50%, -50%); z-index: 999999;  margin: 0 auto;" v-if="hashistory"> 

	 				<div class="btn-group" role="group" aria-label="Basic example" >
					  <button type="button" class="btn btn-primary border-right" @click="historyback()"> <i class="fal fa-arrow-left mr-2"></i> Go back</button>
					  <button type="button" class="btn btn-primary" @click="clearhistory()"><i style="font-size:15px;" class="fal fa-times fa-sm" @click="addnoaccesstolist = false" ></i></button>
					</div>

	 			</div>		 


		<template v-if="displayrouterview=='404' || displayrouterview=='403' || displayrouterview=='400'">
			<Fourofour v-if="displayrouterview=='404'"></Fourofour>
			<Fourothree v-if="displayrouterview=='403'"></Fourothree>
			<Fouroo v-if="displayrouterview=='400'"></Fouroo>
		</template>
		<template v-else>
			<router-view name = "layout" :showbanner="showbanner" ></router-view>
			<div class = "main-view">
				<router-view :showbanner="showbanner" ></router-view>
			</div>
			<router-view name = 'auth'></router-view>
		</template>
		
	</div>
</template>
<script type="text/javascript">
	
</script>
<script>
	import {bus} from '@/main'
	import newsletterviewer from '@/views/newsletter/newsletterviewer'
	import Newslettersapi from '@/services/api/newsletters'
	import Signinapi from '@/services/api/signin'

	import auth from '@/cognito.js';
	import cookie from 'vue-cookie'

	import Fourothree from '@/views/errors/403.vue'
	import Fourofour from '@/views/errors/404.vue'
	import Fouroo from '@/views/errors/400.vue'
	
	const Bowser = require("bowser");
	export default {
		name: 'App',
		components:{newsletterviewer, Fourothree, Fourofour, Fouroo},
		data(){
			return{
				openguide: false,
				displayrouterview : "normal"
			}
		},
		computed: {

			clearhistory(){

				let query = Object.assign({}, this.$route.query);
				delete query.history;
				this.$router.replace({ query });


			},

			hashistory(){

				return this.$route.query.history && this.$route.query.history != ''  ? true : false

			},
			
			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

			loggedinuser(){
			    return this.$store.getters.user
			},
			token(){
			    return localStorage.token
			},
			isIdle() {
				return this.$store.state.idleVue.isIdle;
			},
			forcerefresh(){
				return this.$store.state.forceRefresh
			},
			browser(){
				return  Bowser.getParser(window.navigator.userAgent);
			},
			showbanner(){
				const browser = Bowser.getParser(window.navigator.userAgent);
				const isValidBrowser = browser.satisfies({

				  // per platform (mobile, desktop or tablet)
				  desktop:{
				  	safari: '>=9',
				  	chrome: ">=20.1.1432",
				  	firefox: ">31",
				  	'microsoft edge': ">1",
				  },
				  tablet:{
				  	'microsoft edge':'>1',
				  	safari: '>=9',
				  	firefox: ">31",
				  	chrome: ">=20.1.1432",
				  	'Samsung Internet for Android': ">=1"
				  },
				  mobile: { 
				  	'microsoft edge':'>1',
				  	safari: '>=9',
				  	firefox: ">31",
				  	chrome: ">=20",
				  	'Samsung Internet for Android': ">=1"
				  }	
				});
				var toreturn =  !(isValidBrowser || this.$store.getters.get_routes.indexOf(this.$route.path.toLowerCase()) > -1 ) 
				return  toreturn
				//return true
			}
		},

		watch:{

			'$route' (to, from){

				if(from.name == 'registration'){
					this.openguide = true
				}


			},


			'$store.state.screenSize': function(){
			},
		},

		mounted(){


      		

			let self = this

			setTimeout(function() {

				bus.$off('onupdatedisplayrouterview')
	     		bus.$on('onupdatedisplayrouterview', self.updatedisplayrouterview )

			 }, 100);

			//bus.$on('onupdatedisplayrouterview',"updatedisplayrouterview")
			//setTimeout(function() { bus.$on('craeteaToast', function(e){self.popToast(e)}) }, 100);
			setTimeout(function() { bus.$on('craeteaToast', function(e){self.popToast(e)}) }, 100);
			this.getWindowWidth()
			this.$nextTick(function() {window.addEventListener("resize", self.getWindowWidth);})


			this.startkeepalivetoken()
			this.initADdata()
		},
		methods:{

			historyclear(){

				this.$route.query.history = null

			},

			historyback(){

				let backto = this.$route.query.history

				this.$router.push(backto )
			
			},

			updatedisplayrouterview( newview ){

				this.displayrouterview = newview
			},

			initADdata(){

				var tokens = auth.auth.getSignInUserSession()
				this.$store.dispatch('setSamldata', tokens)

				/*console.log( tokens, "zzzz")

				if (!auth.auth.isUserSignedIn()) {
					var jwt = Signinapi.getjwtUserInfo()

	        		jwt.then( function(response){
	            		store.dispatch('setSamldata', response)
	            	})
	            }else{
	            	cookie.set('signinmethod', "");
	            }*/


			},

			/*let cognitoKeepaliveInterval: any = null

			startCognitoKeepaliveInterval() {
			  if (cognitoKeepaliveInterval) clearInterval(cognitoKeepaliveInterval)

			  cognitoKeepaliveInterval = setInterval(async () => {
			    const session = await Auth.currentSession()
			    const user = await Auth.currentAuthenticatedUser()

			    user.refreshSession(session.getRefreshToken(), () => {
			      log.info('refreshed auth session')
			    })
			  }, 30 * 60 * 1000) // 30 minutes
			},*/

			startkeepalivetoken(){

				var signinmethod = this.$cookie.get('signinmethod');


				if( signinmethod == "saml" ){

					console.log('keep alive started')

					var interval = 60 * 1000 * 30 // 

					setInterval(async () => {

					if (auth.auth.isUserSignedIn()) {
					console.log('still active')
					}else{
					console.log('try refresh')

					var refreshtoken = auth.auth.getSignInUserSession().getRefreshToken().refreshToken

					if( refreshtoken ){
					auth.auth.refreshSession( refreshtoken )
					}else{
					console.log( "no refreshtoken")
					}

					//
					}


					}, interval ) // 30 minutes


				}

				

			},

			getWindowWidth(){
				if( window.innerWidth < 768 ){
					this.$store.dispatch('SET_SCREENTYPE','mobile')
				}else if(window.innerWidth < 1024){
					this.$store.dispatch('SET_SCREENTYPE','tablet')
				}else{
					this.$store.dispatch('SET_SCREENTYPE','desktop')
				}
				
				let width = window.innerWidth
				let height = window.innerHeight

				let screenSize =  (width <= 576 || height <= 576) && window.matchMedia("(orientation: landscape)").matches ? (width < height ? width : height) : width
				this.$store.dispatch('SET_SCREENSIZE',screenSize)
			},

			dimissdbanner(){
				localStorage.browersupportclick = true
			},


			popToast(q) {

			let btoasts = document.getElementsByClassName("b-toast")

			var query = Object.assign( {content:'',title:'',append:true, sup:"We\'ve sent you an email confirming this action." ,settings:{} ,variant:'default'}, q)

	        // Use a shorter name for this.$createElement
	        const h = this.$createElement
	        // Create the message
	        const id = `my-toast-${btoasts.length+1}`

	        let val = 1

	        let intval = setInterval( function(){
	        	val += 1
	        }, 100);


	        if( val >= 100 ){
	        	clearInterval(intval);
	        }


	        const vNodesMsg = h(
	        	'div',
	        	{ class: ['m-0'] },
	        	[
	        	h('div',{class:['mb-2']},
	        		[
	        		h('span',{}),`${query.content}`,
	        		]),
	        	h('div',{class:[]},[
	        		h('span',{class:[]}),`${query.sup}`
	        		]),
	        	h('p'),
	        	h('b-progress', { props: { value: 10, max: 10, variant:'primary', class:'progresslow' } })
	        	]
				)
				
	        //console.log(h)
	        // Create the title
	        const vNodesTitle = h(
	        	'div',
	        	{ class: ['w-100','mr-2'] },
	        	[
	        	h('h4', { class: 'float-left font-weight-normal align-self-center modal-title' },query.title),
	        	h( 'a', { class : 'dialog-close-button toast-close-button float-right align-self-center', on: { click: () => this.$bvToast.hide(id) } }, [ h( 'i', { class: 'fal fa-times' }, '') ] ),
	        	
	        	]
	        	)



	        var delay = 5 * 1000

	        //noAutoHide : true,
	        this.$bvToast.toast([vNodesMsg], {
	        	id: id,
	        	title: [vNodesTitle],
	        	autoHideDelay: delay, 	        	
	        	solid: true,
	        	appendToast: true,//query.append,
	        	variant: 'primary',
	        	noCloseButton: true
	        })


	       

	        let self = this

	        setTimeout( function(){

	        	/*let toasted = document.getElementById(id);
	        	
	        	let pb = toasted.getElementsByClassName("b-toast")
	        	console.log(pb)*/

	        	//self.$root.$on('bv::toast:shown', function(){
	        		$('.progress-bar', '#'+id).addClass('progresslow')
	        	//})

	        }, 500)
	        

	        this.$root.$on('bv::toast:shown', function(){
	        	$('.progress-bar').addClass('progresslow')
	        })


	        if( q.callback ){


		        this.$root.$on('bv::toast:hidden', function(){
		        	q.callback()
		        })

	        }




	    },
	},
}
</script>

<style  lang="scss">
@import './scss/abstracts/variable.scss';


.alert-dismissible .close {
	padding: 0px !important;
}


.progress{
	margin-bottom: -12px;
	margin-left: -12px;
	margin-right: -12px;
	border-radius: 0px !important;
	height: 4px !important;  
	background-color: #ffffff !important;
	-webkit-transform: translateZ(0) !important; 
}

.progress-bar.progresslow {
	width: 1px !important;
	transition: width 5s !important;
	-webkit-transition: width 5s !important;
}


#browser-banner{
	z-index: 1000;
}

</style>
