import Vue from 'vue'
import moment from 'moment';

Vue.filter('statusoptionicon', function (value, error = "icon") {

  var html = ""
  
  if( value.statusoption ){
    if(value.statusoption.color){
       html = '<i class="'+ value.statusoption.icon + ' ' + value.statusoption.color +'"></i> '
    }else if (error == "icon"){
      html = '<i class="fas fa-ban"></i>'

    } else if (error == "NA"){
      html = 'N/A'
    }
    else {
      html = 'N/A'
    }
   

  }else{

    if( value.color == 'green' ){
      html = '<i class="fas fa-check green"></i> '
    }else if( value.color == 'red' ){
      html = '<i class="fas fa-times red"></i> '
    }else if( value.color == 'yellow' ){
      html = '<i class="fas fa-exclamation yellow"></i> '
    }else if( value.color == 'blue' ){
      html = '<i class="fas fa-info blue"></i> '
    }else if (error == "icon"){
      html = '<i class="fas fa-ban"></i>'

    } else if (error == "NA"){
      html = 'N/A'
    } else {
      html = 'N/A'
    }
    
  }
  return html
  /*if (!value) return ''html
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)*/
})

Vue.filter('dateformat',function ( date ) {
				if (!date) return ''
					return moment( date ).format('YYYY-MM-DD')
			})

Vue.filter('dateformatstr', function ( date ) {
				if (!date) return ''
					return moment( date ).format('DD MMM, YYYY')
			})

Vue.filter('timeFormat',function( unixtime ){

				var date = new Date( unixtime * 1000 )

				var str = ""

				if( date != "" ){
					str = moment( date ).format('YYYY-MM-DD')
				}

				return str 
			})

Vue.filter('timeFormatstr',function( unixtime ){

				var date = new Date( unixtime * 1000 )

				var str = ""

				if( date != "" ){
					str = moment( date ).format('DD MMMM, YYYY')
				}

				return str 
			})


Vue.filter('timefromnow',function(obj){
	
	
            if( obj != "" ){

              var date = new Date(obj);

              var str = ""

              if( Math.abs(moment().diff(date)) < (45 * 1000) ) { // 1000 milliseconds
                str = "Just now"
              }else{
                str = moment(obj).fromNow();
              }            
              
             
            }
           
            return str 
          })