<template>
	<div class = 'mx-auto'>
		
		<a @click="opennewsletterfromfeeds( newsletter._id )" v-if="newsletter.cockpit">
			<div class="card noshadow">
				<div class="sub-title px-2"><span class="my-2">{{newsletter.cockpit.name}}</span></div>
				<div class="line"></div>
				<div class="card-title">{{newsletter.name}}</div>
				<div class="line"></div>
				<div class = "newsletterdate"><span class = "text-dark">{{ newsletter.published.date | dateformat}}</span></div>
			</div>
		</a>

		<a @click="opennewsletterfromnewsletterpages( newsletter._id , cockpit._id)" v-else>
			<div class="card noshadow">
				<div class="sub-title px-2"><span class="my-2">{{cockpit.name}}</span></div>
				<div class="line"></div>
				<div class="card-title">{{newsletter.name}}</div>
				<div class="line"></div>
				<div class = "newsletterdate"><span class = "text-dark">{{ newsletter.published.date | dateformat}}</span></div>
			</div>
		</a>




	</div>
</template>
<script type="text/javascript"></script>
<script>

	
	import moment from 'moment';
	import autosize from 'autosize'
	export default{
		name:'newsfeedCard',

		props:{
			newsletter:Object,
			cockpit:{
				type: Object,
				default:() => {}
			}
		},

		filters: {

		},

		components:{},
		methods:{
			opennewsletterfromfeeds( newsletterid ){
				///v-on:click="shownewsletters(newsletter._id)"
				this.$router.push({query:{nid:newsletterid,page:'newsletter'} })
				this.$emit('onopennewsletterfromfeed', newsletterid, this.newsletter.cockpit._id )
				
				//$('#newsfeedmodal').modal('hide')
				//$('#newslettermodal').modal('show')
			},
			opennewsletterfromnewsletterpages(newsletterid){
				this.$router.push({query:{nid:newsletterid,page:'newsletter'} })
				this.$emit('onopennewsletterfromfeed', newsletterid, this.cockpit._id )
			}
		}
	}

</script>