<template>
	<div class = "d-flex flex-row my-auto" >  
		<b-button  :disabled = "disabled ||loading || disabled1" :size="size" :variant="variant" :class ="btnclass"><i v-if = "loading" class="fa fa-circle-o-notch fa-spin"></i> {{text}}</b-button>
		<b-button name ="OK" class = "ml-2"   :disabled = "disabled ||loading || disabled2" :size="size" :class ="btnclass" :variant="variant1"><i v-if = "loading" class="fa fa-circle-o-notch fa-spin"></i>{{text1}}</b-button>
	</div>
</template>

<script type="text/javascript"></script>

<script type="text/javascript">
	export default{
		name:"button-gruppe",
		props:{

			btn1class:{
				type:String,
				default:'ml-auto'
			},
			btnclass:{
				type:String,
				default:''
			},
			size:{
				type:String,
				default:''
			},

			text:{
				type:String,
				default:'Cancel'
			},

			text1:{
				type:String,
				default:'OK'
			},

			variant:{
				type:String,
				default:'secondary'
			},
			variant1:{
				type:String,
				default:'primary'
			},

			default:{
				type:String,
				default:'enter'
			},

			loading:{
				type:Boolean,
				default:false
			},

			disabled:{
				type:Boolean,
				default:false
			},

			disabled1:{
				type:Boolean,
				default:false
			},

			disabled2:{
				type:Boolean,
				default:false
			}
		},
		computed:{

		},
		mounted(){
			let self = this
			if(this.default == 'enter' ){
				$(document).on('keypress',function(e) {
				    if(e.which == 13 && !self.disabled && !self.disabled2) {
				    	if(!self.loading){
				    		self.$emit('yes')
				    	}
				    }
				});
			}	
		},

		unmounted(){
			console.log('unmounted')
			$(document).off('keypress')
		}


	}
</script>