<template>
	<li class="nav-item" @click="itemclicked(item)">
        <div class = "main-bottom-icon-div">
            <div class = " bottom-icon-div" >
              	<router-link @click="itemclicked(item)" v-if = "1==2" :id ="item.id" :to="item.to" class = "red d-inline bottom-icon-button" 
              	:class='{"is-active":isactive}'>
                	<div class=" d-flex align-items-center flex-column">
                  		<i class="far fa-border-all bottom-icon" :class = "item.icon"></i>
                  		<span class = "bottom-icon-text" >{{item.name}}</span>
                	</div>
              	</router-link>
              	<a  v-else class = "redbutton d-inline bottom-icon-button" :class='{"is-active":isactive}'>
              		<div class=" d-flex align-items-center flex-column">
                  		<i class="far fa-border-all bottom-icon" :class = "item.icon"></i>
                  		<span class = "bottom-icon-text" >{{item.name}}</span>
                	</div>
              	</a>
            </div>
        </div>
    </li>
</template>

<script>
	export default{
		name: 'bottombaritem',
		props:{
			item:{
				type:Object,
				default: () => {}
			},
		
		},

		methods:{

			itemclicked( item ){


				if( item.to.path ){

					if( item.to.path.indexOf("myaccount") > -1 ){

						//console.log("myaccount")

						this.$emit('onopenmenu', null, "Account" )
					}else{
						this.$router.push( item.to.path )
					}
					
				}else{
					this.$router.push( "/" )
				}				

				//location.href = item.to.path 
			}
		},

		computed:{

			isactive(){

				return this.item.active.indexOf(this.$route.meta.navindex) > -1
			},

			/*listeners(){

		        return{
		          ...this.$listeners,
		          click: event =>
					this.$emit('click', event, this.item.name),
		        }
		    }*/

		}
	}
</script>