<template>
	<b-modal v-model="isshown" @hidden = "modalhidden" size="sm" @shown="modalshown">
    <template v-slot:modal-header>
      <h3 class="modal-title">Create new organization</h3>
      <a class = "close-modal dialog-close-button">
        <i class="fal fa-times fa-lg fa-lg" @click="isshown = false"></i>
      </a>
    </template>
    

    <base-input label ="Organization name" hasfocus class ="mb-2" :text="orgname" @input="value => { orgname= value }"  type="text" id="orgname" maxlength="70" placeholder="Organization name" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Organization name'" :showError ="showerror" :name="orgname" :isrequired="true"/>

    Organizations are used to group users, boards and objectives together.
    <template v-slot:modal-footer="{ ok,cancel}">
       <button-gruppe @no ="isshown = false" @yes ="createorg"></button-gruppe>    
    </template>

  </b-modal>  
</template>

<script type="text/javascript"></script>

<script>

export default{
	name: 'orgsettings',
	props:{
      shown:Boolean,
      showorgkey:""
    },
    data(){
       return{
          isshown:false,
          orgname: '',
          showerror: false

          
       } 
    },
    watch:{
        shown : function( e ){
          this.isshown = e
        }
    },

    computed:{

       orgkey(){
        return this.$route.params.orgname
      },
     
    },

    methods:{

      modalshown(){
        this.orgname = ""

      },
      createorg(){
        let self = this

        if( this.orgname != "" ){

          this.$store.dispatch('createOrganizations', {"name": this.orgname, "t": this.$store.getters.token}).then(res =>{

            self.isshown = false


            if( res.data.organization.key && res.data.organization.key ){
              this.$router.push( '/'+ res.data.organization.key +'/organizations/about?select' )
            }else{
              this.$router.push( '/' )
            }     


          })

        }else{
          this.showerror = true 
        }


      },
      modalhidden(evt){
        this.$emit('onmodalhidden')
        this.isshown = false
        this.showError = false
      }
    },
    components:{
    }
}
</script>