<template>
	<b-button v-bind ="attrs" v-on ="listeners" >
		<slot>
			{{label}}
		</slot>
	</b-button>
</template>

<script type="text/javascript"></script>

<script>
	export default {
		name: 'base-bnt',
		props:{
			label: {
				type:String,
				default: 'OK'
			},
			default:{
				type: String,
				default: 'none'
			}
		},
		computed:{
			attrs(){
				return this.$attrs
			},
			listeners(){

				return{
					...this.$listeners,
				}
			}
		},
		mounted(){
			let self = this
			if(this.default == 'enter' ){
				$(document).on('keypress',function(e) {
				    if(e.which == 13 && !self.disabled && !self.disabled2) {
				    	if(!self.loading){
				    		self.$emit('click')
				    	}
				    }
				});
			}	
		},

		unmounted(){

			$(document).off('keypress')
		}

	}
</script>