const router ={
  	state:{
     history: []
  	},
  	mutations:{
      POP: state => state.history.pop(),
      PUSH: (state, route) => state.history.push(route)
  	},
  	actions:{
  		record({commit,state},route){
	        const history = state.history

	        // Reloading same page
	        if (history.length && history[history.length - 1].name === route.name) {
	            commit('POP')
	            commit('PUSH', route)
	            return false
	        }
	        // Going back
	        else if (history.length > 1 && history[history.length - 2].name === route.name) {
	            commit('POP')
	            return false
	        }
	        else {
	            commit('PUSH', route)
	            return true
	        }
    	}
  	},
  	getters:{
  		getRouterByMeta: (state) => (filter,key) =>{
  			let route = state.history.filter(element => filter.indexOf(element.meta[key]) > -1 )

  			if(route.length){
  				return route[route.length - 1]
  			} else{
  				return 0
  			}
  			
  		}
  	},
    modules: {
    
  }
}


export default router

