<template>
	<b-input-group>

	<template #prepend v-if="prependstr!=''">
	  <b-input-group-text class="plain">{{ prependstr }}</b-input-group-text>
	</template>
      <b-form-input
      	:readonly="!canwrite"
      	@keyup="ondatekeyup"
      	@change="ondatepicked"
        id="proximity-picker"
        v-model="pickedvalue"
        type="text"
        placeholder="YYYY-MM-DD"
        autocomplete="off"
        style="width: 50%;"
        :class="buttonVariant"
        ref="datepickerinput"
        size=""
      ></b-form-input>
      <b-input-group-append> 
        <b-form-datepicker v-if="canwrite" :min="mindate" ref="datepickerbutton" button-only right menu-class="proximity" id="proximitydatepicker" no-key-nav="true" nav-button-variant="primary" reset-button-variant="btn btn-danger" @input="ondateselect" @hidden="datepickerdone()" :hide-header="true" :label-help="null" style="width: 50%;" :button-variant="buttonVariant" v-model="pickedvalue" :date-format-options="{  month: '2-digit', day: '2-digit', year: 'numeric' }" calendarLocale="en-US" locale="en-US" reset-button
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
</template>

<style type="text/css" scoped>

.input-group-text{
	padding-left: 0;
}

	.plain{
	background: none !important;
	border: none;
	width: 100px;
	}

.custom-control-inline{
	margin-right: 0;
}

.input-group-append{
	background-color: #ffffff !important;
}



</style>

<script type="text/javascript"></script>

<script>

	import moment from 'moment';

	export default {
		name: 'base-bnt',
		data(){
			return {
				truvalue:true,
				pickedvalue:"",
				datepickerstate:0,
				datechanged:false
			}
		},
		props:{

			mindate: {
				type:Date,
				default: null
			},
			maxdate: {
				type:Date,
				default() {
            return new Date()
        }
			},
			label: {
				type:String,
				default: 'OK'
			},
			default:{
				type: String,
				default: 'none'
			},
			datevalue:{
				type: String,
				default: 'none'
			},
			defvalue:{
				type: String,
				default: 'none'
			},
			prependstr:{
				type: String,
				default: ''
			},
			allowempty:{
				type: Boolean,
				default: false
			},
			canwrite:{
				type: Boolean,
				default: false
			},
		},
		computed:{

			ismobile(){

				return this.$store.state.isMobile
			},
			attrs(){
				return this.$attrs
			},
			listeners(){

				return{
					...this.$listeners,
				}
			},
			buttonVariant(){

				let toreturn = ' border-normal'

				if( this.datepickerstate == 1 ){
					toreturn = ' border-success'
				}
				if( this.datepickerstate == 2 ){
					toreturn = ' border-danger'
				}

				return toreturn
			},
		},
		mounted(){

			this.pickedvalue = this.datevalue
			/*let self = this
			if(this.default == 'enter' ){
				$(document).on('keypress',function(e) {
				    if(e.which == 13 && !self.disabled && !self.disabled2) {
				    	if(!self.loading){
				    		self.$emit('click')
				    	}
				    }
				});
			}*/	
		},

		methods:{

			ondatepicked(){


				let self = this

				setTimeout( function(){


					
					//return false


					let target = self.$refs.datepickerinput

					let inputdatestr = target.value
					let inputdate = new Date(inputdatestr)
			

					/*if( (inputdate instanceof Date && !isNaN(inputdate.valueOf())) == false ){

						inputdate = moment( inputdate ).format('YYYY-MM-DD')
						self.pickedvalue = inputdate
					}	*/				

					if( inputdatestr == "" && self.allowempty == true ){

						//this.updaterisk( this.activerisk, 'proximity', "")
						self.$emit('ondatepicked', "")
						self.datepickerstate = 1
						self.$emit('statechanged', 0)

						target.blur()
						setTimeout( ()=>{ 
								self.datepickerstate=0
								self.$emit('statechanged', 0) 
							}, 2500)
						return false
					}

					//alert( inputdate )
					//alert( moment.isDate( inputdatestr ) )

					if( !isNaN( inputdate.getFullYear() ) ){
						inputdatestr = moment( inputdate ).format('YYYY-MM-DD')
						self.pickedvalue = inputdatestr
					}else{
						inputdatestr = target.value
						self.pickedvalue = self.defvalue
						self.datepickerstate = 2
						self.$emit('statechanged', 2)

						setTimeout( ()=>{ 
								self.datepickerstate=0
								self.$emit('statechanged', 0) 
							}, 2500)
						return false
					}

					if( self.mindate ){

						let newdate = new Date(inputdatestr)

						if( newdate < self.mindate ){

							self.pickedvalue = self.defvalue

							self.datepickerstate = 2
							self.$emit('statechanged', 2)

							setTimeout( ()=>{ 
									self.datepickerstate=0
									self.$emit('statechanged', 0) 
								}, 2500)

							return false
						}
					}


					let dateparts = inputdatestr.split("-")

					if( ( dateparts[0] && dateparts[0].length == 4 )  && ( dateparts[1] && dateparts[1].length == 2) && ( dateparts[2] && dateparts[2].length ==2 ) ){


						//console.log( dateparts[0], dateparts[1], dateparts[2], "xxxxxxx")


						let newdate = new Date(inputdatestr)
						//console.log(newdate)
						
						if( (newdate instanceof Date && !isNaN(newdate.valueOf())) == false ){
							//this.proximityrisk = ""
							//this.updaterisk( this.activerisk, 'proximity', "")
							self.$emit('ondatepicked', "")
							self.datepickerstate = 2
							self.$emit('statechanged', 2)
							
							setTimeout( ()=>{ 
								self.datepickerstate=0
								self.$emit('statechanged', 0) 
							}, 2500)
						
						}else{

							let datestr = moment( newdate ).format('YYYY-MM-DD')

							//console.log( datestr , "bbvv")

							self.$emit('ondatepicked', datestr)
							///this.updaterisk( this.activerisk, 'proximity', this.proximityrisk)

							self.datepickerstate = 1
							target.blur()
							self.$emit('statechanged', 0)

							setTimeout( ()=>{ 
								self.datepickerstate=0
								self.$emit('statechanged', 0) 
							}, 2500)
						}
					}else{
						self.datepickerstate = 2
						self.$emit('statechanged', 2)
						setTimeout( ()=>target.focus(), 1000)
					}

					
					/**/

					//this.$emit('ondatepicked', e)

					//console.log( e, "picked")

				}, 100)

			},

			datepickerdone(){

				let self = this

				setTimeout( function(){

					self.$refs['datepickerbutton'].blur()
					//window.focus()
				}, 100)

			},

			ondateselect( e ){
				let self = this
				setTimeout( function(){
					self.ondatepicked()
				}, 100)
			},
			ondatekeyup( e ){

				if( e.keyCode != 13 ){
					this.datepickerstate = 4
					this.$emit('statechanged', 4)
				}				

			},

		},
		watch:{

			datevalue : function( e ){
				this.pickedvalue = e
			}
		},

		unmounted(){

			$(document).off('keypress')
		}

	}
</script>