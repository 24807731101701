const board = {
  	state:{
  		cockpitsOwned:[],
		  cockpitsSharedtome:[],
		  myInactiveCockpits:[],
		  myMeasuresOverview:[],
  	},
  	mutations:{
  		SET_COCKPITSOWND(state, cockpits) {
            state.cockpitsowned = cockpits
        },
        SET_COCKPITSSHAREDTOME(state, cockpits) {
            state.cockpitsowned = cockpits
        },
        SET_MYINACTIVECOCKPITS(state, cockpits) {
            state.cockpitsowned = cockpits
        },
        SET_MYMEASURESOVERVIEW(state, cockpits) {
            state.cockpitsowned = cockpits
        },
  	},
  	actions:{

  	},
  	getters:{

  	},
}


export default board