<template>
	<div class="p-2 mt-2 text-left clearfix" style="clear:both;">
       <div class="comment-actions" >
	        <span class="likescounttotal"> 
	        	  <a v-if="loading.like" class="disabled text-muted" disabled="disabled">Like <span>(<i class="fa fa-circle-o-notch fa-spin"></i>)</span>
	        	</a>
	         	
	            <a v-else-if="(indicator.vote=='dislike' || !activeObjective) || feedback=='readonly'" class="disabled text-muted" disabled="disabled">Like <span>({{ indicator.likescount }}) </span>
	        	</a>
	         	
	         	<a v-else-if="indicator.vote=='like'" class="selected" href="#" @click.prevent="$emit('like',postliketype,indicator,false);">Like <span>({{  indicator.likescount }})</span></a>

	            <a v-else-if="indicator.vote==''" href="#" @click.prevent="$emit('like',postliketype,indicator,false);">Like <span>({{ indicator.likescount}})</span>
	        	</a>
	        </span>

	        <span class="dot"><i class="fa fa-circle"></i></span> 

	        <span class="dislikescounttotal">  <a v-if="loading.like" class="disabled text-muted" disabled="disabled">Dislike <span>(<i class="fa fa-circle-o-notch fa-spin"></i>)</span>
	        	</a>
	         	
	        	<a v-else-if="(indicator.vote=='like' || !activeObjective) || feedback=='readonly'" class="disabled text-muted" disabled="disabled">Dislike <span>({{ indicator.dislikescount }})</span></a>

	            <a v-else-if="indicator.vote=='dislike'" class="selected" href="#" @click.prevent="$emit('like',postliketype,indicator,true);">Dislike <span>({{ indicator.dislikescount }})</span></a>

	            <a v-else-if="indicator.vote==''  " href="#" @click.prevent="$emit('like',postliketype,indicator,true);">Dislike <span>({{ indicator.dislikescount }})</span></a>
	        </span>

	       <span class="dot"><i class="fa fa-circle"></i></span> 

	        <span class="toggle_comments mr-3">
	        	  <a v-if="loading.comments" class="disabled text-muted" disabled="disabled">{{commenttype}} <span>(<i class="fa fa-circle-o-notch fa-spin"></i>)</span>
	        	</a>
	         	
	        	<a v-else-if=" ( !activeObjective || feedback=='readonly') && indicator.commentscount == 0" class="disabled text-muted" disabled="disabled">Comments <span>({{ indicator.commentscount }}) </span></a>
	            <a v-else href="#" @click.prevent="$emit('showcomments',indicator)">Comments <span>({{ indicator.commentscount}})</span></a>
	        </span>

        </div>
    </div>
</template>

<script type="text/javascript"></script>

<script>
	import Cockpitsapi from '@/services/api/cockpits'

	export default{
		name:"indicators",
		props:{
			liketype:String,
			feedback:String,
			indicator:Object,
			loading:{
				type:Object,
				default:() => {return {like:false, comments:false}}
			},
			activeObjective:{
				type:Boolean,
				default: true
			},
			commenttype:{
				type:String,
				default:'Comments'
			}
		},
		 data(){
	      return{
	      	

	      }
	    },
		computed:{
			
			postliketype(){

				return this.liketype == 'newsletter' ? 'newsletter' : 'status'
			}
		},
		methods:{

		}
	}
</script>