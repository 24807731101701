<template>
	<div class ="base-icon-label" v-on ="listeners">

		<slot name="label-before">
			<span class ="iconlabelbefore overflow-string iconlabel" v-if="labelbefore">{{labelbefore}}</span>
		</slot>
		<base-icon :icon="icon" :color ="color" :forcemd ="forcemd" :forcesm ="forcesm" :iconSize="iconSize" :forceSize = "forceSize" >
			<template v-slot:icon>
				<slot name="icon"/>
			</template>
		</base-icon>
		<slot name="label-after">
			<span class ="iconlabelafter overflow-string iconlabel" :class = "iconlabelafter" v-if="labelafter">{{labelafter}} <span v-if="badge!=''"  class="badge badge-light">{{badge}}</span></span>

		</slot>
	</div>
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name: 'base-icon-label',
		props: {
			color:{
				type:String,
				default:""
			},
			forceSize:{
				type:String,
				default: ""
			},
			icon: { 
				type: String,
				default:''
			 },
			 labelafter: { 
			 	type:String,
			 	default:'' 
			 },
			 labelbefore: { 
			 	type: String,
			 	default: ''

			 },
			 forcemd:{
				type: Boolean,
				default: false
			},
			
			iconSize:{
				type: String,
				default: "md"
			},
			forcesm:{
				type: Boolean,
				default: false
			},
			badge:{
				type: String,
				default: ""
			},
			iconlabelafter:{
				type:String,
				default: ""
			}

		},
		computed: {
			listeners(){

				return{
					...this.$listeners,
					input: event =>
					this.$emit('input', event.target.value),
				}
			}
		}

	}
</script>