<template>
	<div class="form-group" data-state="idle" v-if="editorstate.name"> 
			<label>{{label}}</label>
			<div class="editable" v-if="(editorstate.name.state=='idle' && editorstate.description.state == 'idle') && ( myrole.toUpperCase() == 'OWNER' || myrole.toUpperCase() == 'ADMINISTRATOR' )"  v-on ="listeners">
				<div class="card p-2 noshadow readonly titletext mb-0" v-text="textvalue">
				</div> 
				<i  v-if="myrole && ( myrole.toUpperCase() == 'OWNER' || myrole.toUpperCase() == 'ADMINISTRATOR')" class="fa fa-pencil editableicon"></i>
			</div>

			<div class="" v-else-if="editorstate.name.state=='idle' ">
				<div class="card p-2 noshadow readonly titletext mb-0" v-text="textvalue">
				</div>
			</div>


			<input v-if="editorstate.name.state=='editing'" id="cockpittitle" type="text" class="form-control inputtext" name="name" placeholder="Enter a title (required)" :maxlength="max" v-model="editorstate.name.content" @blur="onblursavename()">
			<div class = "d-flex">
				<div class="mt-2 text-danger" v-if="errortext">
					<span maxlength="150">{{errortext}}</span>
				</div>
				<div class="mt-2 ml-auto" v-if="editorstate.name.state=='editing' ">
					<button-gruppe text1 = "Save" v-on ="listeners" :disabled2 ="!namehascontent"></button-gruppe>
				</div>
			</div>
		</div>

</template>

<style lang = "scss">
	
	input.inputtext{
	  font-size: 15px;
	  @media (max-width:400px ) {
	    font-size: 15px !important;
	  }
	}
</style>

<script type="text/javascript"></script>

<script>
	export default{
		name:'inputwithpencil',
		props:{
			myrole: {
				type: String,
				default: ''
			},
			max:{
				type: Number,
				default: 70
			},
			editorstate: {
				type: Object,
				default: () => {return {mode: 'idle', name:{content:'',state: 'idle'}, description: {content:'', state: 'idle'}}}
			},

			hasErrorText:{
				type:String,
				default: ''
			},
			inputName:{
				type: String,
				default: 'Name'
			},
			textvalue:{
				type: String,
				default: ""
			},
			label:{
				type: String,
				default: 'Title'
			}
		},
		mounted(){

			//alert('')
		},
		methods: {

			onblursavename(){
				this.$emit('blursavename', '')
			},


		},
		computed: {

			errortext(){
				if(!this.namehascontent){
					return `${this.inputName} is required`
				}else {
					return this.hasErrorText
				}

			},

			namehascontent(){
				var toreturn = true
				if ( this.editorstate.name.content == '' && !this.editorstate.name.content.replace(/\s/g, '').length){
				  toreturn = false
				}
				return toreturn
			},

			

			listeners(){

				return{
					...this.$listeners,
				}
			}
		}
	}
</script>