<template>

      <img :src ="src" v-bind = "$attrs" :class="newclass" class="img-circle" @error="imageonerror($event)" >
</template>

<!-- -->
<style lang ="scss">
	@import'@/scss/abstracts/variable.scss';
	.badge-secondary{
		background-color: #fff !important;
		color:$NO !important;	

	}
	
	.img-circle {
	    /* margin: auto; */
	    /*border-radius: 4px;*/
	    /*width: 100%;*/
	}

	.in-profile{
		border-radius:4px; 
		border: 1px solid #2e477b;
		width: 152px;
	}

	.in-sidebar{
		width: 34px;
		vertical-align: top;
	}
</style>

<script type="text/javascript">
	
</script>

<script>
	export default{
		name:'base-avatar',
		props:{
			loading:{
				type: Boolean,
				default: false
			},
			imgsrc:String,
			name:String,
			silent:{
				type: Boolean,
				default: false
			},
			noimage:{
				type: Boolean,
				default: false
			},
			newclass:{
				type: String,
				default:''
			},
			inprofile:{
				type: Boolean,
				default:false
			},
			user:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			imageonerror( el ){

		      el.currentTarget.src = '/static/img/names/person.png'
		    },

		},
		computed:{
			src(){
				if (this.user){
					if(this.silent){
						return '/static/silent.png'
					}else if( this.user.status == 'stakeholder' || this.user.status == 'risk' || this.user.status == 'masterplan' ){
						return '/static/img/names/address.png'
					}else if(this.user.profilepic !='' && this.user.profilepic != undefined){
						return this.user.profilepic;
					} else if(this.user.firstname){
						return '/static/img/names/name_'+this.user.firstname.charAt(0).toLowerCase()+'.png';
					} else {
						return '/static/noexists.png'
					}
				}else if(this.silent){
					return '/static/silent.png'
				} else if (this.noimage){
					return '/static/noimage.jpg'
				}else if(this.imgsrc !='' ){
					return this.imgsrc;
				} else if(this.name){
					return '/static/img/names/name_'+this.name.charAt(0).toLowerCase()+'.png'
				} else {
					return '/static/img/names/name_.png'
				}

			}
		}
	}
	//  
</script>