// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/stable'
import 'regenerator-runtime/runtime'
//import 'intersection-observer' // Optional


import Vue from 'vue'
import App from './App.vue'

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-8JXCGZZ6S2" }
});

//import Vue from 'vue'

import 'viewerjs/dist/viewer.css';

import router from './router'
import store from './store'

import './beforeRoute'

import axios from 'axios'
import ToggleButton from 'vue-js-toggle-button'
import BootstrapVue from 'bootstrap-vue'
import VueFlatPickr from 'vue-flatpickr-component'
//import VueSlimScroll from 'vue-slimscroll'


import 'intro.js/introjs.css';

//http://npm.taobao.org/package/vue-slimscroll

import vSelect from 'vue-select'
Vue.component('v-select', vSelect);

//import Vue2TouchEvents from 'vue2-touch-events' 
//Vue.use(Vue2TouchEvents)

import 'bootstrap'
//import 'swipe-js/swipe.js'


//css
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')
import 'flatpickr/dist/flatpickr.css';
// import 'datatables.net-dt/css/jquery.dataTables.min.css';

//scss
import './scss/style.scss'



import VueCookie from 'vue-cookie'
// Tell Vue to use the plugin
Vue.use(VueCookie);

// import froala libs
import './utils/froala'
import VueFroala from 'vue-froala-wysiwyg'
//FroalaEditor.ICON_DEFAULT_TEMPLATE = 'font_awesome'


Vue.use(VueFroala)
Vue.use(ToggleButton)
Vue.use(VueFlatPickr)
Vue.use(BootstrapVue)

//Vue.use(VueSlimScroll)
Vue.prototype.$http = axios


window.$ = window.jQuery = require('jquery');
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui-touch-punch")
//require("jquery-ui/ui/widgets/selectable");

//window.dt = require( 'datatables.net' );
Vue.config.productionTip = false;


//filters


import './utils/filters'
import './mixin'
import './utils/directive'

// import './dectenocht'
import buttongruppe from './components/button/button-gruppe'

Vue.component('button-gruppe',buttongruppe)

import './components/_globals.js'
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
 render: h => h(App)
}).$mount('#app')


export const bus = new Vue()
