import Cockpitsapi from '@/services/api/cockpits'

// function updateObj(oldObj, newObj){
//   return Object.assign({}, oldObj , newObj) 
// }

const cockpit = {
  state:{
    logeventId:"",
    cockpit:{},
    isLoaded: false,
    activeIndicator: {},
    activeObjective: {},
    statistics:[]

  },
  mutations:{
    SET_COCKPIT(state, cockpit){
      state.cockpit = Object.assign({}, state.cockpit , cockpit)
    },
    SET_ACTIVEOBJECTIVE(state, objective){
      state.activeObjective = objective
    },

    SET_ACTIVEINDICATOR(state, indicator){
      state.activeIndicator = indicator
    },

    SET_MYOBJECTIVEVS(state, bool){
       state = Object.assign({}, state, bool)
    },
    REVERSE_ISLOADED(state){
      state.isLoaded = !state.isLoaded
    },

    ADD_OBJECTIVESTOSTATISTICS( state, objective ){
      //console.log( objective, "xxxxxxxxxx")

      if( objective.clear ){
        state.statistics = []
      }

      if( objective.objective ){
        state.statistics.push(objective.objective)
      }
      
    },

    // UPDATED_TITLE(state, title){
    //   state.cockpit = Object.assing(state.cockpit , title)
    // },

    // UPDATED_DESCRIPTION(state, description){
    //   state.cockpit = Object.assing(state.cockpit , description)
    // },

    // UPDATED_MEMBERS(state, members){
    //    state.cockpit = Object.assing(state.cockpit , members)

    // },

    // UPDATED_SECTION(state, cockpit){
    //    state.cockpit = Object.assing(state.cockpit , cockpit)
    // },


  },
  actions:{

    setEventId({commit},payload){

      console.log( commit, payload )
    },

    updateObjective({commit},payload){  
   
      console.log( commit, payload )


      /*return new Promise((resolve, reject) =>{
          
          /*Teamsapi.Update(payload)
          .then(response =>{

           // let team = response.data.team
           // commit('SET_LISTTEAM', team)


            let teams = response.data.teams
            commit('SET_LISTALLTEAMS', teams)

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })*/
     // })
    },

    createObjective({commit},payload){


      console.log( commit, payload )

      this.state.logeventId = payload

      //return new Promise((resolve, reject) =>{

          /*Teamsapi.Createnew(payload)
          .then(response =>{
            let team = response.data.team
            
            commit('SET_LISTTEAM',team)
            //router.push({})
            resolve(response)


          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })*/


           /*

          context "GXpBG_24p"
          t "iJxkJcymyqqAISaDdMiD_n3ZN6YgfrMFuA2ZEn6DJ2HKZpFYAdTvyr0GY8fMUCI5"
          cockpitid "61ead5de293cc140584d890b"
          measuretype "measure"
          measuredescription  ""
          measureshortdescription ""
          measurename "test4455"
          measureindex  "true"
          aggregationmethod "average"
          nodes ""
          roles[] "{\"userid\":\"5f7b40bd1f6d496a91c6ffb1\",\"role\":\"Owner\"}"

          */
      //})
    },

    updateCockpitActivity({commit},payload){

      this.state.cockpit.cockpit.isactive = payload.isactive
      console.log( commit, payload, "xxxxxxxxx")

      // commit('ADD_OBJECTIVESTOSTATISTICS', payload)

    },

    addObjectiveToStatistics({commit},payload){

       commit('ADD_OBJECTIVESTOSTATISTICS', payload)

    },

    fechtCockpit({commit},payload){
      var thisPromise = Cockpitsapi.Cockpitspreload({"_id": payload.cid, "token": payload.token })
     // console.log(payload, "preloadddddddddddd")
      if(!payload.cid){

        commit('SET_MYOBJECTIVEVS', {myobjetivevs:true})
        thisPromise = Cockpitsapi.Myobjectives( {"context": payload.orgkey, "token": payload.token } )

      }

      return new Promise((resolve, reject) =>{



          thisPromise.then(response =>{

            console.log( response.data.cockpits , "cockpit" )


            commit('REVERSE_ISLOADED')
            commit('SET_COCKPIT',response.data.cockpits)

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {

          })

      })
    }
  },
  getters:{
    GetCockpitByKeys: (state) => (...keys) =>{

     return Object.keys(state.cockpit)
      .filter(key => keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = state.cockpit[key];
        return obj;
      }, {});
    },
    GetCockpit:(state) => state.cockpit,
    GetBoardStatisticsObjectives:(state) => state.statistics,
    statisticsobjectives: state => state.statistics,
    logeventId: state => state.logeventId
    
  },
  modules: {

  }
}


export default cockpit