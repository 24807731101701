import {baseurl} from './index.js'

import auth from '@/cognito.js';

import axios from 'axios'

axios.defaults.baseURL = baseurl

//axios.defaults.headers.common['flowe-event-id'] = this.$store.getters.logeventId

export default {

	//cognito specific

	Generategettingstarted ( userid, orgid ){

		return axios.get(  '/Rest/Updatewelcomecockpit?user=' + userid + "&org=" + orgid )

	},


	Purgeorginfo( token ){

		return axios.get(  '/Users/Purgeorginfo?t=' + token )

	},

	Generateidpurl( idp ){

		return axios.get(  '/Rest/Corporate?action=idptourl&idp=' + idp )

	},

	Completessosignup( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Corporate', URLparams.toString() )

	},


	Emailtosso( email, sub ){

		return axios.get(  '/Rest/Corporate?action=emailtosso&email=' + email + '&sub=' + sub )

    },

    Flowetoken( subject ){

    	var iid = Math.random().toString(36).slice(2)
		return axios.get(  '/Rest/Corporate?action=gettoken&subject=' + subject + '&idtoken=' + iid )

    },

    getflowetokenfromJwt(){

		//console.log( auth.auth.getSignInUserSession().getIdToken()  , "xxxxx")


        var jwtToken = auth.auth.getSignInUserSession().getAccessToken().jwtToken;
        const USERINFO_URL = '/Rest/Token/?act=getuserfromtoken';
        var requestData = {
            headers: {
                'Authorization': 'Bearer '+ jwtToken
            }
        }
        return axios.get(USERINFO_URL, requestData).then(response => { 
            return response.data;
        });

    },

	getjwtUserInfo(){

		//console.log( auth.auth.getSignInUserSession().getIdToken()  , "xxxxx")


        var jwtToken = auth.auth.getSignInUserSession().getAccessToken().jwtToken;
        const USERINFO_URL = 'https://'+auth.auth.getAppWebDomain() + '/oauth2/userInfo';
        var requestData = {
            headers: {
                'Authorization': 'Bearer '+ jwtToken
            }
        }
        return axios.get(USERINFO_URL, requestData).then(response => { 
            return response.data;
        });
    },

    /*validateAuthcode( authcode ){

	    const config = {
		  headers: {
		    'Content-Type': 'application/x-www-form-urlencoded'
		  }
		}

        const AUTHCODE_URL = 'https://'+auth.auth.getAppWebDomain() + '/oauth2/token';
        var requestData = { "grant_type":"authorization_code", "client_id":"7sm5be3u8j1aosi679e9hr8ono", "redirect_uri":"http://localhost:8080/ssosignin", "code":authcode }
        
        const URLparams = new URLSearchParams(Object.entries(requestData))
        return axios.post(AUTHCODE_URL, URLparams.toString(), config).then(response => { 
            return response.data;
        });
    },*/

	//end cognito

	// sign in a user with email and password

	Loadidp( idp ){

		return axios.get(  '/Rest/Corporate?action=getidp&idp=' + idp )

	},

	Checkifcorporate( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Corporate', URLparams.toString() )

	},

	Signin( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Users/Logindo', URLparams.toString() )

	},

	Signout( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Users/Logoutdo', URLparams.toString() )

	},

	// initiate sign up with email

	Signup( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Users/SignupWithEmail', URLparams.toString() )

	},

	// initiate a forgot password process

	Forgotpassword( email , retoken ){

		return axios.get(  '/Users/ForgotPassword?email=' + email + '&retoken=' + retoken )

	},

	// request a sign in token using google


	Signinwithgoogle( gtoken ){
	
		return axios.get(  '/Users/Createlogin?request=token&token=' + gtoken )

	},
	

	// complete a signup process with an email 

	//{{url}}/Users/Registerdo/?v={{token}}&format=token&firstname={{firstname}}&lastname={{lastname}}&password={{password}}&password2={{password2}}

	Registerdo( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Users/Registerdo/', URLparams.toString() )

	},

	Registercheck( emailid ){

		//https://app.flowe.io/Users/Signup/?authid=KpakLcuY8Q1hHp8KFia4PWYF1S4zc_k8oB9dUZom3KBH_2CMPt7AXi0xoSrVh5On&action=check
		return axios.get( "Users/Signup/?authid=" + emailid + "&action=check" )


	},


	// initiate a sign up using google


	Signupwithgoogle( gtoken ){
	
		return axios.get( '/Users/Authorize?token=' + gtoken + '&mode=signup' )

	},

	// complete a password change


	Passwordchange( payload ){

		// v=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl&eid=83lrBU9aFNE12N5pMbLO0Qi9PL1DHcKB0AHhvql3_ovPvw3p_oaUvBvXXmWwf6mV&
		//password2=asdasdasd&
		//password3=asdasdasdasdas

		//v=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl&eid=TTDfjzv_sCiVbPsx8IgWaWbrnCnoJ5G9kzRAxDnhXP2ZHhRLXhHtGXFuFKNGQLan&password2=aasdf123&password3=asdf1234

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post( '/PasswordReset', URLparams.toString() )

	},

	Passwordchangecheck( authid, emailid ){

		return axios.get( '/Users/PasswordReset/?v=' + authid + '&eid=' + emailid + '&action=check' )
		
	},

	Emailchangeconfirm( emailid ){

		return axios.get( '/Rest/Emailchangeconfirm/?confirm=' + emailid )
		
	}


}

