import Userapi from '@/services/api/users'
import Signinapi from '@/services/api/signin'
import router from '@/router'

const user = {
  state: {
    usertoken: "",
    token: "",
    userInfo: {
      _id:'',
      credential: {
        failedattempts: null,
        isactive: Boolean,
        lastlogindate: '',
        password:''
      },
      email:'',
      firstname: '',
      lastname: '',
      profilepic: '',
      signingoogle: '',
      signinlocal:'',
      status: '',
      saml:{}
    },
  },
  mutations: {
    SET_SAML: (state, samldata) => {
      state.saml = samldata
    },
    SET_USERTOKEN: (state, token) => {
      state.usertoken = token
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.userInfo = user
    },
    SET_GOOGLESIGNIN: (state, fieldvalue) => {
      state.userInfo.signingoogle = fieldvalue
    },
    SET_EMAILSIGNIN: (state, fieldvalue) => {
      state.userInfo.signinlocal = fieldvalue
    },
    SET_PROFILEPIC: (state, profilepic) => {
      state.userInfo.profilepic = profilepic
    },
    SET_USER_FIRST_NAME: (state, firstname) => {
      state.userInfo.firstname = firstname
    },
    SET_USER_LAST_NAME: (state, lastname) => {
      state.userInfo.lastname = lastname
    }
  },
  actions: {

    storeToken( {commit}, payload ){

      return new Promise((resolve) =>{

        commit('SET_USERTOKEN', payload);

         resolve( { "ok":true, "token":payload } ) 
      })
    },

    updateUserDetails({commit}, payload ){

        return new Promise((resolve) =>{

          //console.log(commit, payload ,"pppppp")

          if( payload.field == 'signingoogle' ){
            commit('SET_GOOGLESIGNIN', payload.value);
          }

          if( payload.field == 'signinemail' ){
            commit('SET_EMAILSIGNIN', payload.value);
          }

          

          resolve( { "ok":true, "field": payload.field, "value": payload.value } ) 
           /*Userapi.Details( uuid ).then(response =>{

              commit('SET_TOKEN', uuid);
              commit('SET_USER', response.data.user);
              resolve(response)
              
           }) .catch(error => {console.log(error); reject(error)})
          .finally( () => {
           // console.log( this.state.user.userInfo._id, "cccccc") 
          })*/
        })

    },

    setSamldata({ commit}, tokens){

      return new Promise((resolve) =>{

              commit('SET_SAML', tokens);
              resolve(tokens)

      })
      //.catch(error => {console.log(error); reject(error)})


    },

    fecthUserDetails({ commit}, uuid){
      if( this.state.user.userInfo && this.state.user.userInfo._id == "" ){

        return new Promise((resolve, reject) =>{
           Userapi.Details( uuid ).then(response =>{

              commit('SET_TOKEN', uuid);
              commit('SET_USER', response.data.user);
              resolve(response)
              
           }) .catch(error => {console.log(error); reject(error)})
          .finally( () => {
           // console.log( this.state.user.userInfo._id, "cccccc") 
          })
        })

      }else{

        return new Promise((resolve) =>{ resolve({data: { ok:true , user: this.state.user }})  })
      }     


    },
    doClearsession({commit}){
      return new Promise((resolve) =>{
          commit('SET_TOKEN', '');
          commit('SET_USER', {});

          localStorage.idlein = ""
          localStorage.remember = ""
          localStorage.token = ""
          delete localStorage.userdetails 

          

          //router.push("/sign-out")

          resolve()
      }).catch(error => {console.log(error)})
    },
    doSignOut({commit}){
      return new Promise((resolve) =>{
          commit('SET_TOKEN', '');
          commit('SET_USER', {});

          Signinapi.Signout( { "t": localStorage.token } )

          localStorage.idlein = ""
          localStorage.remember = ""
          localStorage.token = ""
          delete localStorage.userdetails 

          router.push("/sign-out")

          resolve()
      }).catch(error => {console.log(error)})
    },
    updateProfilepicture({commit}, newprofilepic){
      return new Promise((resolve) =>{
          
          commit('SET_PROFILEPIC', newprofilepic);

          //console.log( params, "xxxx")

          resolve()
      }).catch(error => {console.log(error)})
    },

  },

  getters:{
    usertoken: state => state.usertoken,
    token: state => state.token,
    user: state => state.userInfo,
    saml: state => state.saml,
  }
}

export default user