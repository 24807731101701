<template>

  <div class = "">
    
    <div v-if="!indicator">Comments not available, try reloading the page again</div>

    <div class="comments-wrapper" v-if="indicator &&  (indicator.showcomments  || showalways)">


                             
                              <!-- comments start -->

                              <div class="row pl-3 pr-3 pt-3 commentbox" :id = "'addcomments_'+indicator._id" v-if=" activeObjective && feedback == 'readwrite'"> 

                                  

                                <div class="col-md editor px-0">

                                  <span class="text-danger" v-if="error=='noorgaccess'">Sorry. It looks like you no longer have access to this. Please try again or contact your IT administrator if the error persists.</span>

                                 <span class="text-danger" v-if="error=='commentposterror'">Comment not posted. Please try again or contact your IT administrator if the error persists.</span>

                                  <textarea ref="commenttextarea"  @input="messagecomposing($event, 'message', indicator)" :value="message" rows="1" name="commenttext" :placeholder="placeholder" autocomplete="off" class="form-control autogrow form-control ontextareachange" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: auto; min-height: 31px;"></textarea>

                                  <p class="mt-2 text-right"> 
                                    
                                    <button-gruppe 
                                                text1="Post" 
                                                text="Post anonymous"
                                                default ="none"
                                                variant ="primary" 
                                                :loading = "status.message == 'loading'"
                                                :disabled = "allowmessagepost == false" 
                                                @yes ="Postcomment(indicator,indicator._id, commenttype,false,message,'addcomments', 'message')"
                                                @no = "Postcomment(indicator,indicator._id, commenttype,true,message,'addcomments','message')" 
                                                >
                                                
                                      </button-gruppe>
                                  </p>
                                </div>        
                              </div>

                                

                              <template v-if="indicator.comments">
                              <div class="comment-replies" v-for="comment in indicator.comments" v-bind:key="comment._id" :id="comment._id" >
                                  
                                 <div class="comment-group">
            
                                 
                                    <div class="comment-row pt-2">

                                       <!-- comment line -->
                                            <a name="commentanchor"></a>
                                            <div class="d-flex" v-if="comment.silent == false && comment.user">
                                              <template v-if ="comment.user">
                                                <base-avatar :silent ="comment.silent" :imgsrc = "comment.user.profilepic" :name = "comment.user.firstname" :newclass="'commentpic round align-self-center'" > </base-avatar> 
                                              </template>
                                              <template v-else>
                                                 <base-avatar :silent ="comment.silent" :newclass="'commentpic round align-self-center'" > </base-avatar>  
                                              </template>
                                             
                                               
                                                <div class="align-self-center pl-2">
                                                    <div class="comment-name">{{ comment.user.firstname }} {{ comment.user.lastname }}</div>
                                                    <div class="comment-date" >{{comment.dateposted | timefromnow}}</div>
                                                </div>
                                            </div>                                            
                                            <div class="d-flex" v-else>
                                            <base-avatar :silent = "true" :newclass="'commentpic round align-self-center'" > </base-avatar> 
                                                <div class="align-self-center pl-2">
                                                    <div class="comment-name">Anonymous</div>
                                                    <div class="comment-date ">{{comment.dateposted | timefromnow}}</div>
                                                </div>
                                            </div>

                                            <div class="d-flex comment-text">
                                                <div class="comment-content" style="white-space: pre-wrap;">{{ comment.text}}</div>                   
                                            </div>
                                            
                                            <div class="d-flex comment-actions">
                                                <div class="comment-content">
                                                    <span class="likescounttotal">

                                                      <a v-if="(comment.vote=='dislike'  || !activeObjective) || feedback=='readonly'" class="disabled text-muted" disabled>Like
                                                        <span>({{ comment.likescount }})</span>
                                                      </a>

                                                      <a v-else-if="comment.vote=='' " href="#" @click.prevent="$emit('onlike','comment',comment,false)">Like
                                                        <span>({{ comment.likescount }})</span>
                                                      </a>
                                                      
                                                      <a v-else-if="comment.vote=='like'" href="#" class="selected" @click.prevent="$emit('onlike','comment',comment,false)">Like
                                                        <span>({{ comment.likescount }})</span>
                                                      </a> 
                                                    </span>
                                                    <span class="dot"><i class="fa fa-circle"></i></span> 
                                                    <span class="dislikescounttotal">
                                                      <a v-if="(comment.vote=='like' || !activeObjective) || feedback=='readonly'" class="disabled text-muted" disabled> Dislike
                                                        <span>({{ comment.dislikescount }})</span>
                                                      </a>

                                                      <a v-else-if="comment.vote==''" href="#" @click.prevent="$emit('onlike','comment',comment,true)">Dislike
                                                        <span>({{ comment.dislikescount }})</span>
                                                      </a>

                                                      <a v-else-if="comment.vote=='dislike'" href="#" class="selected" @click.prevent="$emit('onlike','comment',comment,true)">Dislike
                                                        <span>({{ comment.dislikescount }})</span>
                                                      </a>

                                                    </span> 
                                                    <span class="dot"><i class="fa fa-circle"></i></span> 
                                                    <span class = "toggle_comments">
                                                      <a v-if="(!activeObjective) || feedback=='readonly'" class="disabled text-muted" disabled="disabled">Reply</a>
                                                      <a v-else href="#" @click.prevent="togglereply(comment)">Reply</a> 
                                                    </span> 
                                                </div>
                                            </div>



                                              
                                        <!-- end comment line -->
                                        
                                        <div class="comment-row reply subreply" v-for="(reply,index) in comment.comments" v-bind:key="reply._id" :class="{'hide': ( index >= 3 && comment.hasmorereplies == true )  }">

                                            <div >
                                            <div class="d-flex">
                                              <template v-if ="reply.user">
                                                <base-avatar :silent ="reply.silent" :imgsrc = "reply.user.profilepic" :name = "reply.user.firstname" :newclass="'commentpic round align-self-center'" > </base-avatar> 
                                              </template>
                                              <template v-else>
                                                 <base-avatar :silent ="reply.silent" :newclass="'commentpic round align-self-center'" > </base-avatar>  
                                              </template>
                                                
                                                 <div class="align-self-center pl-2" v-if="reply.silent">
                                                    <div class="comment-name">Anonymous</div>
                                                    <div class="comment-date">{{reply.dateposted | timefromnow}}</div>
                                                </div>
                                                <div class="align-self-center pl-2" v-else>
                                                    <div class="comment-name">{{ reply.user.firstname }} {{ reply.user.lastname }}</div>
                                                    <div class="comment-date ">{{reply.dateposted | timefromnow}}</div>
                                                </div>

                                            </div>
                                            
                                                 
                                                
                                        
                                            <div class="d-flex comment-text">
                                                <div class="comment-content" style="white-space: pre-wrap;">{{reply.text}}</div>                   
                                            </div>
                                            <div class="d-flex comment-actions">
                                                <div class="comment-content">
                                                    <span class="likescounttotal">

                                                       <a v-if="(reply.vote=='dislike' || !activeObjective) || feedback=='readonly'" class="disabled text-muted" disabled >Like
                                                        <span>({{ reply.likescount }})</span>
                                                      </a>

                                                      <a v-else-if="reply.vote==''" href="#" @click.prevent="$emit('onlike','comment',reply,false)">Like
                                                        <span>({{ reply.likescount }})</span>
                                                      </a>
                                                     
                                                      <a v-else-if="reply.vote=='like'" href="#" class="selected" @click.prevent="$emit('onlike','comment',reply,false)">Like
                                                        <span>({{ reply.likescount }})</span>
                                                      </a>

                                                    </span>
                                                    <span class="dot"><i class="fa fa-circle"></i></span> 
                                                    <span class="dislikescounttotal">
                                                      <a v-if="(reply.vote=='like' || !activeObjective) || feedback=='readonly'" class="disabled text-muted" disabled> Dislike
                                                        <span>({{ reply.dislikescount }})</span>
                                                      </a>

                                                      <a v-else-if="reply.vote==''" href="#" @click.prevent="$emit('onlike','comment',reply,true)">Dislike
                                                        <span>({{ reply.dislikescount }})</span>
                                                      </a>
                                                
                                                      <a v-else-if="reply.vote=='dislike'" href="#" class="selected" @click.prevent="$emit('onlike','comment',reply,true)">Dislike
                                                        <span>({{ reply.dislikescount }})</span>
                                                      </a> 
                                                    </span> 
                                                </div>
                                            </div>

                                          </div>
                                        </div> 
                                        <div style="margin-left: 40px;" class="comments-seemore mt-2 comment-actions" v-if="comment.hasmorereplies"><span><a href="#" @click.prevent="seemorereplies(comment)" >See more replies</a></span></div> 

                                    </div>

                                    <div v-if=" activeObjective && feedback == 'readwrite' && comment.writingreply" :id = "'addreply_'+indicator._id" class="d-flex flex-row pt-3 comment-row scrolltoreply" style="margin-left: 40px;">
                                        <div class="col float-right py-0 pl-0 editor commentbox"> 
                                            <textarea ref="replytextarea" rows="1" name="replytext" placeholder="Replying to this comment..." @input="messagecomposing($event, 'reply', indicator)" :value="reply" autocomplete="off" class="ontextareachange form-control autogrow form-control" style="overflow: hidden; overflow-wrap: break-word; resize: none; min-height: 31px;"></textarea> 
                                            <p class="mt-2 text-right">
                                              <button-gruppe 
                                                text1="Reply" 
                                                text="Reply anonymous"
                                                default ="none"
                                                variant ="primary" 
                                                :loading = " status.reply == 'loading'"
                                                :disabled = "allowreplypost == false" 
                                                @yes ="Postcomment(indicator,comment._id,'comment',false,reply,'addreply','relpy')"
                                                @no = "Postcomment(indicator,comment._id,'comment',true,reply,'addreply','reply')" 
                                                ></button-gruppe>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                              

                              </div>
                              </template>

                              <div class="comments-seemore mt-2 comment-actions" v-if="indicator.hasmorecomments"><span><a href="#" @click.prevent="seemorecomments()" >See more comments</a></span></div>

                              
                        </div>

  </div>

	
</template>

<style lang = "scss">


.comment-widgets .comment-name {
    font-weight: 700 !important;
}
 
</style>

<script type="text/javascript"></script>

<script>
  import avatar from '@/views/tools/avatar'
  import  indicators from './indicators'
  import Cockpitsapi from '@/services/api/cockpits'
  import autosize from 'autosize'
  import moment from 'moment';

  import {bus} from '@/main'

	export default{
		name:"comments",
		props:{
      loggedinuser:Object,
			cockpit:Object,
      objectdata:Object,
			indicatorcomments:Array,
      activeObjective:{
        type:Boolean,
        default: true
      },
      oid:{
         type:String,
        default: ''
      },
      placeholder:{
         type:String,
        default: 'Add a comment to the status...'
      },
      feedback:String,
      place:String,
      scope:String,
      commenttype:String,
      showalways:Boolean,
		},

     components:{avatar, indicators},

    data(){
      return{
        message:"",
        indicator:{},
        contantmessage:true,
        reply:"",
        contantreply:true,
        t:null,
        paged:10,
        perpaged:1,
        status: {reply: 'idle', message:'idle'},
        token:"",
        replyboxopened:false,
        error:'',
        commentposthaserror: false
      }
    },
    computed:{

      orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : null
    },

     allowmessagepost(){

        var toreturn = false

        //console.log(this.message)
        if( this.message != '' ) {
          toreturn = true
        }


        return toreturn
      },

      allowreplypost(){

        var toreturn = false

        if( this.reply != '' ) {
          toreturn = true
        }

        //contantmessage || status.message == 'loading'

        return toreturn
      },
    },
    mounted(){

      this.indicator = this.objectdata
      //console.log('indicator')
      moment.locale();

      this.token = localStorage.token


      setTimeout( function(){

        autosize($('textarea'));

      }, 500)

      //bus.$off('initcomments')
      //bus.$on('initcomments', this.initcomponent )
    },
    watch:{      
      'status.message': function(e) {
      //  console.log('status.message', e)
      },
      'indicator.showcomments' : function(e){
       
        if( e == true ){
          let self = this
          this.initseemore(this.scrolltoaddcomment('addcomments'))
          this.$func.addQuery({oid:this.oid, iid:this.indicator._id},this.$route, this.$router)
           
          this.$nextTick(function(){
            
            autosize($('textarea'));
          })
        }else{
          this.perpaged = 1
        } 

           
      },
      showalways: function(){
        
      
        let self = this
        this.initseemore(setTimeout(function(){     
          autosize($('textarea'));
          self.scrolltoaddcomment('addcomments')
        },100))
        
      },
      objectdata : function(e){
        this.indicator = e
      },

      reply:function(e){

        //this.scrolltoaddcomment('addreply')
        
      },
      message:function(e){

         //this.scrolltoaddcomment('addcomments')

      },

    },
    methods:{
      Postlike(ev1, ev2, ev3){
        let self = this
        this.$nextTick(function(){
          self.$emit('onlike','comment',ev2,ev3)
        })

      },

      initseemore(callback){

        let self = this

        setTimeout( function(){

         /* if( self.indicator.comments ){
           for( var i in self.indicator.comments ){

           }
          }*/

          var perpage = self.perpaged * self.paged

          var comments = []
          if( self.indicator.measureid ){
            comments = $('.comment-replies', '#index_indicatordetails_' + self.indicator.measureid + '_' + self.indicator._id)
          }else{
            comments = $('.comment-replies', '#newslettercomments')

          }
          

          var numitems = 0
          var commentitems = []
          $.each( comments, function( i,o ){

            commentitems.push( o )                   
            //var replies = $('.subreply', o )

            // $.each( replies, function( j,k ){
            //  commentitems.push( k )
            // })
          })



         $(commentitems).addClass('hide')

          if( commentitems.length >= perpage ){

          //  alert( perpage )

            var tohide = commentitems.slice( 0 , perpage )

            //$(commentitems).addClass('hide')
            $(tohide).removeClass('hide')
            //console.log(  )
            self.indicator.hasmorecomments = true

            //console.log( commentitems.length, perpage )
          }else{

            self.indicator.hasmorecomments = false
            $(commentitems).removeClass('hide')
          }
          if(callback){
            callback()
          }
       }, 50) 

      },

      seemorereplies( comment ){
        //console.log( comment.hasmorereplies )
        comment.hasmorereplies = false
      },

      seemorecomments(){
        this.perpaged += 1
        this.initseemore()
      },

      initcomponent( object ){
        this.indicator = object
      },

      stopcheckingforcomments( indicator ){
        this.$emit( 'onupdatecomments', indicator )
      },

      checkforcomments( indicator ){
        
        let self = this

      },

      messagecomposing( ev, obj, indicator){

        this.error = ""

        if( obj == 'message' ){
          this.message = ev.target.value
        }

        if( obj == 'reply' ){
          this.reply = ev.target.value
        }
        //console.log(ev, "vvv")
        this.checkforcomments(indicator)
      },

      indicatordate(indicator){

        // console.log(indicator.replace('.','-').replace('.','-').replace(' ',''))
        return moment(indicator).format("MMM Do YY")
      },

      closeallreplyboxes(){

        this.reply = ""

        for(var i in this.$refs.replytextarea ){
          $(this.$refs.replytextarea[i]).height("")
        }

        for( var i in this.indicator.comments ){
          this.indicator.comments[i].writingreply = false
        }
      },

      togglereply( comment ){

        

        var origvalue = comment.writingreply

        this.closeallreplyboxes()

        comment.writingreply = origvalue ? false : true

        this.replyboxopened = comment.writingreply

        //$('#'+cid+' .subreply .commentbox').toggleClass('hide')
        //this.$emit('updated')
        
        let self = this
        this.$nextTick(function(){
          if( comment.writingreply == true ){
            self.scrolltoaddcomment('addreply')
          }
          autosize($('textarea'));
          // $('html, body').animate({}, 1);
        })
       
        // if(reply !==null && reply != '' && reply != undefined){}
        // if(message !==null && message != '' && message != undefined){}
      },

      scrolltoaddcomment(id){ 
      let self = this
      this.$nextTick(function(){

        var body = $('.modal-body').length ? $('.modal-body'): window

        var comments = document.getElementById(id+'_'+self.indicator._id);
       // console.log('comments', comments)

        if( comments ){


          var textbox = $('#'+id+'_'+self.indicator._id+' textarea');
          var bounding = comments.getBoundingClientRect()
          if (!(
              bounding.top >= 0 &&
              bounding.left >= 0 &&
              bounding.right <= (body.innerWidth || document.documentElement.clientWidth) &&
              bounding.bottom <= (body.innerHeight || document.documentElement.clientHeight)
            )){

                
                comments.scrollIntoView({block: 'end',behavior:'smooth'})
                

              } 
                       
              setTimeout(function(){if(textbox.focus()){textbox.focus()}},400)
        }



        })
      },

      

      Postcomment(indicator, iid, commenttype, issilent, message, id, type){ 
          this.status.message = 'loading'
          this.status.reply = 'loading'
          this.error = ''

          var self = this
          Cockpitsapi.Postcomment({ "context":this.orgkey, "cockpitid":this.cockpit._id,"iid": iid,"commenttype":commenttype,"issilent":issilent,"message":message,"t":this.token,"action": "postcomment"})
            .then( function(response){
              if( response.data.ok ){     

                $(self.$refs.commenttextarea).height("")  
                //console.log('post',response.data)
                self.$emit('oncommentposted', indicator, response.data.comments, commenttype, iid, response.data.numcomments )
                //console.log(response.data.comments)
                self.message = ""
                self.reply = ""

                self.closeallreplyboxes()

                
              }else{

                if( response.data.hasorgrole == false ){
                  self.error = 'noorgaccess'
                }else{
                  self.error =  'commentposterror'
                }

                
              }

            })
            .catch(error => console.log(error))
            .finally( () => {
              this.status.message = 'idle'
              this.status.reply = 'idle'
              //do everything
              //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
              
            })
          

        },
    },
    
    updated(){
      
      if(this.message != null && this.message != '' && this.message != undefined){
        this.contantmessage = false
      }else{
        this.contantmessage=true
      }

       if(this.reply != null && this.reply != '' && this.reply != undefined){
        this.contantreply = false
      }else{
        this.contantreply = true
      }
    },

	}
</script>