<template>
  <div>
    
  </div>
</template>
<style>
  .comment-widgets .comment-replies .round {
    width: 35px;
    height: 35px;
    line-height: 34px !important;
    font-size: 18px;
  }
  .round {
      background: none !important;
  }

</style>

<script type="text/javascript"></script>

<script>

  import Cockpitsapi from '@/services/api/cockpits'
  import autosize from 'autosize'

	export default{
		name:"commentsecion",
		props:{
      commentsprop:Object,
      userid:Object,
      indicatorid:Object,
      activeCockpit:{
        type:Boolean,
        default: true
      },
      activeObjective:{
        type:Boolean,
        default: true
      },
      feedback:String,
      place:String,
      comments:Array
		},
    data(){
      return{
        message:null,
        contantmessage:true,
        comments:{},
        counter:1,

      }
    },
    computed:{

      orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : null
    },

    },
    mounted(){

      this.comments = commentsprop
    },
    methods:{

      closeallreplyboxes(){

        this.$emit('oncloseallreplyboxes')
      },

      togglereply( comment ){

        var origvalue = comment.writingreply

        this.closeallreplyboxes()

        comment.writingreply = origvalue ? false : true
        
        //$('#'+cid+' .subreply .commentbox').toggleClass('hide')
        //this.$emit('updated')
        autosize($('.autogrow'));
        // if(reply !==null && reply != '' && reply != undefined){}
        // if(message !==null && message != '' && message != undefined){}
      },

      Postcomment(indicator, iid ,commenttype,issilent,message){ 

          var self = this
          Cockpitsapi.Postcomment({ "context":this.orgkey, "iid": iid,"commenttype":commenttype,"issilent":issilent,"message":message,"auth":self.loggedinuser._id,"action": "postcomment"})
            .then( function(response){
              if( response.data.ok ){         
                self.$emit('oncommentposted', response.data.comments, commenttype, iid, response.data.numcomments )
                
                self.message = ""
                self.reply = ""
                self.closeallreplyboxes()
              }

            })
            .catch(error => console.log(error))
            .finally( () => {

              //do everything
              //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
            })
          

        },
    },
    
    updated(){
      
      if(this.message != null && this.message != '' && this.message != undefined){
        this.contantmessage = false
      }else{
        this.contantmessage=true
      }

       if(this.reply != null && this.reply != '' && this.reply != undefined){
        this.contantreply = false
      }else{
        this.contantreply = true
      }
    }

	}
</script>