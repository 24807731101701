<template>
	<div>Token validation</div>
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name:'projectstatusreporten',

		mounted() {

		}

	}
</script>