<template>
	<b-modal id="narbarmenu" v-model="isshown"  :modal-class="'dropup-menu-modal'" hide-backdrop size='' @shown="modalshown" @hidden="modalhidden" hide-footer hide-header> 
    <div>
      <slot/>
      
    </div>
   </b-modal>
 </template>
 <style lang ="scss">
  #narbarmenu___BV_modal_outer_{
    z-index: 1039 !important;
  }
  #narbarmenu{
    display: block;
    .modal-dialog{
      bottom:-90%;
      transition: transform 0.25s ease-in;
      -webkit-transition: -webkit-transform 0.25s ease-in;
      -moz-transition: transform 0.25s ease-in;
      -o-transition: transform 0.25s ease-in;

      transition: transform 0.2s ease-out;
      -webkit-transition: -webkit-transform 0.2s ease-out;
      -moz-transition: transform 0.2s ease-out;
      -o-transition: transform 0.2s ease-out;
      transition: transform 0.2s ease-out;
    }
    
    
    &.show{
      .modal-dialog{
        transform: translate(0, -469px);
        transform: translate(0, -469px);
      }
    }
  } 


</style>
 <script type="text/javascript"></script>

 <script>
  export default{
   name: 'popupmenu',
   props:{
    myobjectivesid:String,
    shown:Boolean,
    items:Array

  },
  data(){
   return{
    isshown:false,
  } 
},
watch:{
  shown : function( e ){
    this.isshown = e
  }
},
computed:{
},

methods:{
  modalshown(evt){

  },
  modalhidden(evt){
    let self =  this

    setTimeout(function(){
      self.$emit('onmodalhidden')
      self.isshown = false
    },250)
   
  }
}
}
</script>