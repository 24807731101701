<template>
	<div class = "newsfeed mx-auto">
		<div class="newsfeed-list" v-if="newsletters.length>0">
			<ul>
				<li v-for="newsletter in newsletters" v-bind:key="newsletter._id" class = "d-felx flex-wrap justify-content-center ">
					<newsfeedCard :cockpit ="cockpit" :newsletter="newsletter" @onopennewsletterfromfeed="opennewsletterforviewing" />

				</li>
			</ul>
		</div>
		<div v-else>
			- there are no reports to show -
		</div>
	</div>
</template>
<style  lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';
	ul{
		width: 300px
	}

	.newsfeed .newsfeed-list{
		padding:0 !important;
	}

	.newsfeed-list ::v-deep{
		.mx-auto{
			margin:0 !important;
		}
	}

</style>

<script type="text/javascript"></script>

<script>
	import newsfeedCard from './newsfeedCard'

	export default {
		name:'newsfeedlist',
		props:{
			newsletters:Array,
			cockpit:{
				type: Object,
				default:() => {}
			}
		},

		methods:{
			opennewsletterforviewing(nid,cid){
				this.$emit('opennewsletterforviewing',nid,cid)

			},
			
		},

		components:{
			newsfeedCard
		},

	}	

</script>