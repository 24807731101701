<template>
	<div id="progressstatusreporttemplate" v-if="cockpit && reportlang=='en'" >

	<h1>Project status report</h1>
	<table style="width: 100%; margin-right: calc(37%);">
	<tbody>
		<tr>
			<td style="width: 25%;"><div style="text-align: left;">Project:</div></td>
			<td style="width: 75%;">{{ cockpit.name }}</td>
		</tr>
		<tr><td style="width: 25%;"><div style="text-align: left;">Report:</div></td>
			<td style="width: 75%;">{{ newslettertitle }} </td>
		</tr>
		<tr>
			<td style="width: 25%;"><div style="text-align: left;">Period:</div></td>
			<td style="width: 75%;">{{ timeperiodstart  }} - {{ timeperiodend  }}</td></tr>
		<tr>
			<td style="width: 25%;"><div style="text-align: left;">Author:</div></td>
			<td style="width: 75%;">{{ loggedinuser.firstname }} {{ loggedinuser.lastname }}</td>
		</tr>
		</tbody>
	</table>

	<p><span style="color: rgb(44, 130, 201);">Add your welcome paragraph here... </span></p>

	<template v-for="(section, index ) in cockpit.sections">
	<p :key="'section'+index" v-if="section.objectives.length>0"><strong>{{ section.label }}</strong></p>

	<template v-for="(objective, index ) in section.objectivesdata">
	<p :key="'objectivesection_'+index+objective._id"><img v-if="objective.indicators[ objective.indicators.length - 1]" :src="'/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii"> {{ objective.name }}</p>
	</template>

	</template>

	<p v-if="cockpit.measuresnotinsections && cockpit.sections && cockpit.measuresnotinsections.length>0 && cockpit.sections.length > 0"><strong>Not grouped</strong></p>

	<template v-for="(objective, index ) in cockpit.measuresnotinsections">
	<p :key="'objectivenotsection'+index"><img v-if="objective.indicators[ objective.indicators.length - 1 ]" :src="'/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" class="fr-fic fr-dii"> {{ objective.name }}</p>
	</template>

	<!-- page_break -->

	<h1>Project objectives status</h1>
	
	<p><span style="color: rgb(44, 130, 201);">Add a couple of sentences describing the overall status for the project…</span></p>

	<div class="fr-img-space-wrap">
	<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
	<span class="fr-img-wrap">
	<img :src="objectivesgraphimage" style="width: 621px;" class="fr-fic fr-dib fr-fil"><span class="fr-inner">Average status development since the beginning of the project</span>
	</span>
	</span>
	<p class="fr-img-space-wrap2">&nbsp;</p>
	</div>

	<template v-for="section in cockpit.sections">

	<template v-for="(objective, index ) in section.objectivesdata">

		
	 <h3 :key="'oh2'+index+objective._id"><img :key="'oimg'+index" v-if="objective.indicators[ objective.indicators.length - 1 ]" :src="'/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" style="width: 30px;" class="fr-fic fr-dii"> {{ objective.name }}</h3>
		

	<span :key="'osummary'+index+objective._id" style="color: rgb(44, 130, 201);">Write a short summary of the status for the objective</span>

	<template v-for="(indicator, indexindicator ) in objective.indicators" > 

		<p v-if="objective.measuretype == 'measure' && ( new Date(indicator.indicatordate) > new Date(timeperiodstart) && new Date(indicator.indicatordate) < new Date(timeperiodend) )" :key="indicator._id + objective._id + indexindicator"> ({{ indicator.userdetail.firstname }} {{ indicator.userdetail.lastname }} - {{ momentdate(indicator.indicatordate) }} ) <span :key="'message_'+indicator._id" v-html="indicator.message"></span>
		</p>

	</template>

	<p :key="'brbr'+index+objective._id">&nbsp;</p>

	</template>

	</template>


	<template v-for="(objective, index ) in cockpit.measuresnotinsections">

	<div v-if="cockpit.measuresnotinsections.length > 0" :key="'nosectionstatus' + index">
		
	 <h3 :key="'oh2'+index"><img :key="'oimg'+index" v-if="objective.indicators[ objective.indicators.length - 1 ]" :src="'/static/img/'+ objective.indicators[ objective.indicators.length - 1 ].color +'.png'" style="width: 30px;" class="fr-fic fr-dii">{{ objective.name }}</h3>
		
	<span :key="'osummary'+index" style="color: rgb(44, 130, 201);">Write a short summary of the status for the objective</span>

	<template v-for="(indicator, indexindicator ) in objective.indicators" > 

		<p v-if="objective.measuretype == 'measure' && ( new Date(indicator.indicatordate) > new Date(timeperiodstart) && new Date(indicator.indicatordate) < new Date(timeperiodend) ) " :key="indicator._id + objective._id + indexindicator"> ({{ indicator.userdetail.firstname }} {{ indicator.userdetail.lastname }} - {{ momentdate(indicator.indicatordate) }} ) <span :key="'message_'+indicator._id" v-html="indicator.message"></span>
		</p>

	</template>

	<p :key="'brbr'+index+objective._id">&nbsp;</p>

	</div>

	</template>

	<div v-if="cockpit.masterplan">

	<!-- page_break -->	


	<h1>Project Masterplan</h1>

	<p><span style="color: rgb(44, 130, 201);">Add a couple of sentences describing the overall status of the project progress</span></p>

	<div class="fr-img-space-wrap">
	<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
	<span class="fr-img-wrap">
	<img :src="masterplanimage" style="width: 621px;" class="fr-fic fr-dib fr-fil"><span class="fr-inner">Project Masterplan</span>
	</span>
	</span>
	<p class="fr-img-space-wrap2">&nbsp;</p>
	</div>


	<h3>Milestones for the report period</h3>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Track</td>
				<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Milestone</td>
				<td style="width: 20.0000%; background-color: rgb(204, 204, 204);">Date</td>
				<td nowrap="nowrap" style="width: 20.0000%; background-color: rgb(204, 204, 204); text-align:center;">Status</td></tr>

				<template v-if="currentmilestones.length>0">
			<tr v-for="(milestone, index ) in currentmilestones" :key="index" >
				
				<td style="width: 30.0000%;">{{milestone.tracklabel}}</td>
				<td style="width: 30.0000%;">{{milestone.label}}</td>
				<td nowrap="nowrap" style="width: 20.0000%;">{{milestone.eventdate}}</td>
				<td nowrap="nowrap" style="width: 20.0000%; text-align:center;"><img src="http://app.flowe.io/static/img/red.png" class="fr-fic fr-dii"> <img src="http://app.flowe.io/static/img/green.png" class="fr-fic fr-dii"></td>	
				
			</tr>
			</template>
			<template v-else>
			<tr>
				<td colspan="4">No Milestones in the report period</td>				
			</tr>
			</template>
			
		</tbody>
	</table>

	<p><br></p>

	<h3>Future milestones</h3>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Track</td>
				<td style="width: 30.0000%; background-color: rgb(204, 204, 204);">Milestone</td>
				<td style="width: 20.0000%; background-color: rgb(204, 204, 204);">Date</td>
				<td nowrap="nowrap" style="width: 20.0000%; background-color: rgb(204, 204, 204); text-align:center;">Status</td></tr>
				<template v-if="futuremilestones.length>0">
			<tr v-for="(milestone, index ) in futuremilestones" :key="index">
				
				<td style="width: 30.0000%;">{{milestone.tracklabel}}</td>
				<td style="width: 30.0000%;">{{milestone.label}}</td>
				<td nowrap="nowrap" style="width: 20.0000%;">{{milestone.eventdate}}</td>
				<td nowrap="nowrap" style="width: 20.0000%; text-align:center;"><img src="http://app.flowe.io/static/img/red.png" class="fr-fic fr-dii"> <img src="http://app.flowe.io/static/img/green.png" class="fr-fic fr-dii"></td>
				
			</tr>
			</template>
				<template v-else>
					<tr><td colspan="4">No Milestones found</td></tr>
				</template>
		</tbody>
	</table>

	<p><br></p>

	</div>

	<div v-if="cockpit.risks">

	<!-- page_break -->

	<h1>Project Risk</h1>

	<p><span style="color: rgb(44, 130, 201);">Add a couple of sentences describing the overall status of the project risks</span></p>
	

	<div class="fr-img-space-wrap">
	<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 421px;" draggable="false">
	<span class="fr-img-wrap">
	<img :src="riskgraphimage" style="width: 421px;" class="fr-fic fr-dib fr-fil"><span class="fr-inner">The project risk matrix</span>
	</span>
	</span>
	<p class="fr-img-space-wrap2">&nbsp;</p>
	</div>
	

	<h3>Top 3 risk</h3>



	<p>These are the risks with the highest Consequence x Probability</p>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Risk</td>
				<td style="width: 35%; background-color: rgb(204, 204, 204);">Mitigating action</td>
				<td style="width: 20%; background-color: rgb(204, 204, 204);">Owner</td>
				<td style="width: 15%; background-color: rgb(204, 204, 204);text-align:center;">CxP Indicator</td>
			</tr>
			<tr v-if="riskstop.length == 0 ">
				<td colspan="5">No risks added.</td>
			</tr>
			<tr v-for="(risk, index ) in riskstop" :key="index">
				<td style="width: 30%;">{{ risk.label }}</td>
				<td style="width: 35%;">{{ risk.mitigatingaction }}</td>
				<td style="width: 20%;" nowrap="nowrap">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
				<td style="width: 15%; text-align:center;"><img height="12px" :src="'/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
			</tr>
		</tbody>
	</table>

	<p>&nbsp;</p>


	<h3>Top 3 Closest risks</h3>


	<p>These are the 3 risk with the closest proximity date</p>


	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 25%; background-color: rgb(204, 204, 204);">Risk</td>
				<td style="width: 25%; background-color: rgb(204, 204, 204);">Mitigating action</td>
				<td style="width: 20%; background-color: rgb(204, 204, 204);">Owner</td>
				<td style="width: 15%; background-color: rgb(204, 204, 204);text-align:center;">CxP Indicator</td>
				<td style="width: 15%; background-color: rgb(204, 204, 204);">Date</td>
			</tr>
			<tr v-if="risksclose.length == 0 ">
				<td colspan="5">No risks have proximity dates</td>
			</tr>
			<tr v-else v-for="(risk, index ) in risksclose" :key="index">
				<td style="width: 25%;">{{ risk.label }}</td>
				<td style="width: 25%;">{{ risk.mitigatingaction }}</td>
				<td style="width: 20%;">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
				<td style="width: 15%;text-align:center;"><img height="12px" :src="'/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
				<td style="width: 15%;" nowrap="nowrap">{{ risk.proximity }}</td>
			</tr>
		</tbody>
	</table>

	<p>&nbsp;</p>

	<h3>Risks with no mitigating action or no proximity date</h3>

	<p>The following risks either do not have a mitigation action or the date of expected occurrence has not been entered</p>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Risk</td>
				<td style="width: 40%; background-color: rgb(204, 204, 204);">Owner</td>
				<td style="width: 20%; background-color: rgb(204, 204, 204);text-align:center;">CxP Indicator</td>
			</tr>
			<tr v-if="risksother.length == 0 ">
				<td colspan="3">No risks without mitigating actions or a proximity date.</td>
			</tr>
			<tr v-else v-for="(risk, index ) in risksother" :key="index">
				<td style="width: 30%;">{{ risk.label }}</td>
				<td style="width: 40%;">{{ risk.owner ? risk.owner.firstname : '' }} {{ risk.owner ? risk.owner.lastname : '' }}</td>
				<td style="width: 20%;text-align:center;"><img height="12px" :src="'/static/img/dot_'+tocolorimage( risk.cxpcolor )+'.png'" ></td>
			</tr>
		</tbody>
	</table>

	<p><br></p>

	<!-- page_break -->

	<h3>Risk development</h3>


	<div class="fr-img-space-wrap">
	<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
	<span class="fr-img-wrap">
	<img :src="riskchartimage" style="width: 621px;" class="fr-fic fr-dib fr-fil"><span class="fr-inner">Project risk profile development</span>
	</span>
	</span>
	<p class="fr-img-space-wrap2">&nbsp;</p>
	</div>

	</div>

	<div v-if="cockpit.stakeholders">
	<!-- page_break -->	

	<h1>Stakeholder management</h1>
	<p><span style="color: rgb(44, 130, 201);">Add a couple of sentences describing the overall status for stakeholder management</span></p>
	<p><br></p>

	<div class="fr-img-space-wrap">
	<span class="fr-img-caption fr-fic fr-dib fr-draggable fr-fil" style="width: 621px;" draggable="false">
	<span class="fr-img-wrap">
	<img :src="stakeholderimage" style="width: 621px;" class="fr-fic fr-dib fr-fil"><span class="fr-inner">Stakeholder map</span>
	</span>
	</span>
	<p class="fr-img-space-wrap2">&nbsp;</p>
	</div>

	<h3>Manage closely</h3>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
				<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
			</tr>

			<tr v-if="stakeholdersmanage.length==0">
				<td colspan="3">There no stakeholders in this quadrant.</td>
			</tr>

			
			<tr v-else v-for="(stakeholder, index ) in stakeholdersmanage" :key="index">
				<td style="width: 30%;">{{ stakeholder.label }}</td>
				<td style="width: 30%;">
					<span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span>
				 </td>
				<td style="width: 40%;">{{ stakeholder.activities }}</td>
			</tr>
		</tbody>
	</table>

	<p>&nbsp;</p>

	<h3>Keep informed</h3>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
				<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
			</tr>

			<tr v-if="stakeholdersinformed.length==0">
				<td colspan="3">There no stakeholders in this quadrant.</td>
			</tr>

			<tr v-else v-for="(stakeholder, index ) in stakeholdersinformed" :key="index">
				<td style="width: 30%;">{{ stakeholder.label }}</td>
				<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}</span><br></td>
				<td style="width: 40%;">{{ stakeholder.activities }}</td>
			</tr>
		</tbody>
	</table>

	<p>&nbsp;</p>

	<h3>Keep satisfied</h3>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
				<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
			</tr>

			<tr v-if="stakeholderssatisfied.length==0">
				<td colspan="3">There no stakeholders in this quadrant.</td>
			</tr>
			<tr v-else v-for="(stakeholder, index ) in stakeholderssatisfied" :key="index">
				<td style="width: 30%;">{{ stakeholder.label }}</td>
				<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span></td>
				<td style="width: 40%;">{{ stakeholder.activities }}</td>
			</tr>
		</tbody>
	</table>

	<p>&nbsp;</p>

	<h3>Monitor</h3>

	<table style="width: 100%;">
		<tbody>
			<tr>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Stakeholder</td>
				<td style="width: 30%; background-color: rgb(204, 204, 204);">Contacts</td>
				<td style="width: 40%; background-color: rgb(204, 204, 204);">Activities</td>
			</tr>
			<tr v-if="stakeholdersmonitor.length==0">
				<td colspan="3">There no stakeholders in this quadrant.</td>
			</tr>
			<tr v-else v-for="(stakeholder, index ) in stakeholdersmonitor" :key="index">
				<td style="width: 30%;">{{ stakeholder.label }}</td>
				<td style="width: 30%;"><span v-for="(user, uindex ) in stakeholder.usercontacts" :key="uindex">{{ user.firstname }} {{ user.lastname }}<br></span></td>
				<td style="width: 40%;">{{ stakeholder.activities }}</td>
			</tr>
		</tbody>
	</table>

	<p>&nbsp;</p>

	</div>

	</div>

	<!-- end english template -->
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name:'projectstatusreporten',

		mounted() {

		}

	}
</script>