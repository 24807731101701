<template>
	<div class = "icon-holder" v-on= "listeners" v-bind ="$attrs" :class="iconclass">
		<slot name ="icon">
			<span class="iconwrapper">
				<i :class ="icon"></i>
			</span>
		</slot>
	</div>
</template>
<style scoped lang ="scss"> 
@import '@/scss/abstracts/variable.scss';

.iconwrapper:hover i, .iconwrapper:focus i{
	font-weight: 700;
}
	
</style>
<script type="text/javascript"></script>
<script>
	export default{
		name: 'base-icon',
		props:{
			color:{
				type:String,
				default:""
			},
			forceSize:{
				type:String,
				default: ""
			},
			icon:{
				type: String,
				default: 'none'
			},
			boxSizeDesktop:{
				tope: String,
				default: 'md'
			},
			iconSize:{
				type: String,
				default: "md"
			},
			forcemd:{
				type: Boolean,
				default: false
			},
			
			forcesm:{
				type: Boolean,
				default: false
			}
		},

		computed:{
			iconclass(){
				var str = ''
				str += ` icon-${this.iconSize} `
				// str += `icon-${this.boxSizeDesktop}`

				if(this.forcemd){
					str += ' forcemd'
				}

				if(this.forcesm){
					str += ' forcesm'
				}
				if(this.forceSize){
					str += ` force${this.forceSize}`
				}

				if(this.color){
					str += ` icon-${this.color}`
				}

				return str
			},
			
			listeners(){

				return{
					...this.$listeners,
					input: event =>
					this.$emit('input', event.target.value),
				}
			}
		}

	}
</script>