	x<template>
	<div>
	<div class="d-flex align-items-center form-row form-group statusopts">

	</div>
	</div>
</template>

<script type="text/javascript"></script>

<script>
	export default {
		name: 'base-bnt',
	}
</script>