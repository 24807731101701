<template>
  <div class="dropdown" id="thisdropdown" tabindex="0">
    <slot name ="dropdown-btn">
      <a class="dropdown-toggle noicon" data-display="static" :id ="arialabelledby" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <slot name ="dropdown-menu"/>
      </a>
    </slot>
    <div class="dropdown-menu" :aria-labelledby="arialabelledby">
      <slot name = "dropdown-items"/>
    </div>
  </div>
</template>
<style lang = "scss">
  .dropdown{
    &.nopadding{
      .dropdown-item{
        padding: 0
      }
    }
  }
</style>
<script type="text/javascript"></script>
<script>
  export default{
    name: 'base-dropdown',
    props:{
      arialabelledby:{
        type: String,
        default: 'dropdownMenuButton'
      }
    },
    mounted(){
      let self = this
      $('#thisdropdown').on('show.bs.dropdown', function () {
       $('#mainsidebar').addClass('opened')
        self.$emit('action', 'show')

      })

      $('#thisdropdown').on('hide.bs.dropdown', function () {
       $('#mainsidebar').removeClass('opened')
        self.$emit('action', 'hide')

      })

    },
    methods:{
      dropdownclicked(){

        //alert('')
      }
    }
  }
</script>