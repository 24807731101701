
<script type="text/javascript"></script>
<template>

	<div>
		

		<b-modal v-model = "isshown" id="newsfeedmodal" no-fade size="sm" hide-footer :modal-class="'simple-side-modal newsfeed'"  ok-only no-stacking @hidden = "onmodalhidden"  @hide = "onmodalhide" scrollable> 
			<template v-slot:modal-header="{cancel}">
			<!-- <a class = "close-modal dialog-hover-button mr-2">
	            <i class="far fa-bell " ></i>
	          </a> -->
				<h3 class="modal-title">Newsfeed</h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times " @click="cancel()"></i>
				</a>

			</template>

			<div class="newsfeed-list">
				<ul>
					<li v-for="newsletter in newsletters" v-bind:key="newsletter.id" class = "row justify-content-center ">
						<newsfeedCard :newsletter="newsletter" @onopennewsletterfromfeed="opennewsletterfromfeed" />

					</li>
				</ul>
			</div>
		</b-modal>
	</div>


</template>
<script>

	import {bus} from '@/main'

	import {newsfeedCard} from './components'

	import newsletterviewer from '@/views/newsletter/newsletterviewer'
	
	
	export default{
		name:'newsfeed',
		props:{
			newsletters:Array,
			shown: Boolean,
		},
		data(){
			return{
				isshown: false,
				opennewsletterfromfeedmodal : false,
				token:"",
				newsletterid:"",
				acktivcockpitname:""

			}
		},

		components:{
			newsfeedCard, newsletterviewer
		},

		computed:{

		},

		watch:{

			shown : function( e ){
				console.log('shownesletter',e)
				this.isshown = e
			}
		},
		mounted(){

			this.token = localStorage.token

		},



		methods:{

			opennewsletterfromfeed( nid, cid ){
				//alert('openfromfeed')


				bus.$emit('onopennewsletterforviewing', nid, cid)
				
			},
			
			shownewsletter(newsletterid, autherid){
				this.$emit('shownewsletter',newsletterid, autherid)
			},
			onmodalhidden(){
				this.$emit('onmodalhidden')
			},
			onmodalhide(){
				// this.$router.push(this.$route.path)
			}
			

		}
	}

</script>