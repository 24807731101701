import Teamsapi from '@/services/api/teams'
//import router from '@/router'
//import Vue from 'vue'

//import cookie from 'vue-cookie'

const organizations = {
 state:{
  listallteams:null,
  listteam:null,
  selectedteam:{}
  },
  mutations:{
    
    SET_LISTALLTEAMS(state, payload){
      state.listallteams = payload
    },
    SET_LISTTEAM(state, payload){
      state.listteam = payload
    },
    /*SET_LIST_BY_KEY(state, payload){
      state.listorganizations[payload.key] = Object.assign({} , state.listorganizations[payload.key], payload.content)
    },*/
   /* SET_SELECTORGANIZATIONS(state, payload){

      if( payload ){
        cookie.set('organizationkey', payload.key)
        state.selectedorganizations =  Object.assign({} , state.selectedorganizations, payload)
      }

    },*/

    /*SET_ORGANIZATIONS_BY_KEY(state, payload){
      Vue.set(state.selectedorganizations,payload.key, payload.content)
    },

    SET_ORGANIZATION_BY_OBJECT(state, payload){      
      state.selectedorganizations = Object.assign({} , state.organization, payload.content)
    },*/


  },
  actions:{

    fetchAllTeams({commit},payload){

      commit('SET_LISTALLTEAMS', null)
      commit('SET_LISTTEAM', null)

      let token = payload.token
      let orgkey = payload.orgkey
      let teamid = payload.teamid
      
      return new Promise((resolve, reject) =>{

          Teamsapi.Getall(token, orgkey)
          .then(response =>{

            let teams = response.data.teams
            commit('SET_LISTALLTEAMS', teams)

            if( teamid && teamid != '' ){

              for( var i in teams ){

                if( teams[i]._id === teamid ){
                  commit('SET_LISTTEAM', teams[i])
                }
              }


              //let teamindex = teams.findIndex(x => x._id === teamid);
              
            }
            

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },


    fetchMembersofaTeam({commit},payload){

      let token = payload.token
      let teamid = payload.teamid
      
      return new Promise((resolve, reject) =>{

          Teamsapi.Getusers(token, teamid)
          .then(response =>{

            let team = response.data.team
            commit('SET_LISTTEAM', team)
            
            //commit('SET_SELECTORGANIZATIONS', defaultorganizations)
            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },


    fetchOneTeam({commit},payload){

      let token = payload.token
      let teamid = payload.teamid
      
      return new Promise((resolve, reject) =>{

          Teamsapi.Getone(token, teamid)
          .then(response =>{

            let team = response.data.team
            commit('SET_LISTTEAM', team)
            
            //commit('SET_SELECTORGANIZATIONS', defaultorganizations)
            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },


    /*fetchUsers({commit},payload){

      return new Promise((resolve, reject) =>{

          Organizationsapi.Getusers(payload.token, payload.teamid)
          .then(response =>{

            let users = response.data.users
            let usersarr =[]
            let userids = []
            for (var i in users){
              let newuser  = users[i].detail
              newuser.role = users[i].role
              newuser.userid = users[i].userid
              usersarr.push( newuser)
              userids.push(newuser.userid)
            }
            
            commit('SET_ORGANIZATIONS_BY_KEY', {key:'users', content:usersarr})
            commit('SET_ORGANIZATIONS_BY_KEY', {key:'usersids', content:userids})

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },*/

    updateTeams({commit},payload){  
   

      return new Promise((resolve, reject) =>{
          
          Teamsapi.Update(payload)
          .then(response =>{

           // let team = response.data.team
           // commit('SET_LISTTEAM', team)


            let teams = response.data.teams
            commit('SET_LISTALLTEAMS', teams)

            resolve(response)

          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    },

    /*selectOrganizations({commit},payload){

      return new Promise((resolve, reject) =>{

          Organizationsapi.Select(payload.token, payload.key)
          .then(response =>{
            if(response.data.ok){
                var org = response.data.organization
                commit('SET_SELECTORGANIZATIONS', org) 


              
            }
            resolve(response)  


          }) 
          .catch(error => {console.log(error); router.push('404'); reject(error)})
          .finally( () => {
          })
      })
    },*/

    createTeam({commit},payload){

      return new Promise((resolve, reject) =>{

          Teamsapi.Createnew(payload)
          .then(response =>{
            let team = response.data.team
            
            commit('SET_LISTTEAM',team)
            //router.push({})
            resolve(response)


          }) 
          .catch(error => {console.log(error); reject(error)})
          .finally( () => {
          })
      })
    }
  },
  getters:{
    teams: state => state.listallteams,
    team: state => state.listteam
  },
  modules: {

  }
}


export default organizations