import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

//axios.defaults.headers.common['flowe-event-id'] = this.$store.getters.logeventId

export default {

	Details( token, key ){
		
		//https://capi.flowe.io/Users/Details?token=Q9mgvEmLRr4sTv3712v8wFYZ22aWP9dxwMjrvsZuM73O7SSFBFWSPMqBf7BhaoGl

		return axios.get(  '/Rest/Organizations?action=details&t=' + token + '&context=' + key )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	GetActiveorg( token ){

		return axios.get(  '/Organization/GetActiveorg?t=' + token )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	

	Getfiltered( token ){

		return axios.get(  '/Rest/Organizations?action=getfiltered&t=' + token )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Getall( token ){

		return axios.get(  '/Rest/Organizations?action=getall&t=' + token )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Select( token, key ){

		return axios.get(  '/Rest/Organizations?action=select&t=' + token + '&context=' + key )
		//return axios.get(  '/My/Dashboard/?t=' + payload.userid  )

	},

	Update( payload ){

		/*
		"name": name ,
		"t": token
		*/

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Organizations/?action=update', URLparams.toString() )
	},
	

	Createnew( payload ){

		/*
		"name": name ,
		"t": token
		*/


		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Organizations/?action=new', URLparams.toString() )
	},

	Createandjoin( token ){

		/*
		"name": name ,
		"t": token
		*/

		return axios.get(  '/Rest/Organizations?action=joinnew&t=' + token )


	},

	Getusers( token, key ){

		/*
		"key": organization key ,
		"t": token
		*/

		return axios.get(  '/Rest/Organizations?action=getusers&t=' + token + '&context=' + key )


	},

	Joinorgfromemail( token, emailid, context ){

		return axios.get( "Users/Joinorg/?t=" + token + "&authid=" + emailid + "&context=" + context + "&action=check" )


	},




}

