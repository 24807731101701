import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const baseComponent = require.context(
	'./base',false, /base-[\w-]+\.vue$/
	)
baseComponent.keys().forEach(fileName => {
	const baseCompontentConfig = baseComponent(fileName)

	const baseCompontentName = upperFirst(
			camelCase(fileName.replace(/^\//,'').replace(/\.\w+$/, ''))
		)
	Vue.component(baseCompontentName, baseCompontentConfig.default || baseCompontentConfig)
})

const advancedComponent = require.context(
	'./advanced',false, /[\w-]+\.vue$/
	)
advancedComponent.keys().forEach(fileName => {
	const advancedCompontentConfig = advancedComponent(fileName)

	const advancedCompontentName = upperFirst(
			camelCase(fileName.replace(/^\//,'').replace(/\.\w+$/, ''))
		)
	Vue.component(advancedCompontentName, advancedCompontentConfig.default || advancedCompontentConfig)
})